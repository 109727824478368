const loading_error = 'loading error, please try again';
const common_filter = {
  to: 'To',
  from: 'From',
  time_range: 'Time Range',
  title: 'Filter Panel',
};

export const tr = {
  common: {
    cancel_btn_label: 'İptal',
    clear_btn_label: 'Temizle',
    close_btn_label: 'Kapat',
    reset_btn_label: 'Sıfırla',
    save_btn_label: 'Değişiklikleri Kaydet',
    save: 'Kaydet',
    select: 'Seç',
    update: 'Güncelle',
    add: 'Ekle',
    edit: 'Düzenle',
    details: 'detaylar',
    new: 'Yeni',
    change: 'Değiştir',
  },
  pages: {
    accounting: {
      title: 'Hesap Planı',
      accounting: 'Muhasebe',
    },
    inventory: {
      title: 'Envanter',
    },
    company_details: {
      settings: 'Ayarlar',
      supplier: 'Tedarikçi',
      reports: 'Raporlar',
      order: 'Sipariş',
    },
    chart_of_accounts: {
      title: 'Hesap Planı',
      asset: 'Varlık',
      expense: 'Gider',
      liability: 'Yükümlülük',
      revenue: 'Gelir',
      drawing: 'Çekme',
      add_account: 'Hesap ekle',
      account_type: 'Hesap Türü',
      all: 'Tümü',
      alert: {
        title: 'Error',
        text: 'Request Failed',
        strong_text: 'Refetch',
      },
    },
    register: {
      head_title: 'İşlemler',
      title: 'Kayıt',
      card: 'Kart',
      table: 'Tablo',
      add_register: 'Ekle',
    },
    cash_transfer: { title: 'Nakit Transferi', add_transfer: 'Nakit Transferini Kaydet' },
    register_id: {
      title: 'İşlemler',
      add_register: 'bir işlem kaydet',
    },
    expense: {
      title: 'Ödeme / Gider',
      add_expense: 'Gider Kaydetme',
    },
    drawing: {
      title: 'Çekme',
      add_drawing: 'bir çizimi Kaydetme',
    },
    sale: {
      daily_title: 'Günlük Satış Raporu',
      monthly_title: 'Aylık Satış Raporu',
      today_live_report: 'Bugünün Canlı raporu',
      store_dropdown_label: 'Mağaza',
      daily: 'Günlük',
      monthly: 'Aylık',
      yearly: 'Yıllık',
    },
    purchase: {
      title: 'Satın Alma Raporu',
      business_year: 'İş Yılı',
      total_purchase: 'Toplam Satın Alma',
      purchase_payable: 'Ödemeli Satın Alma',
      january: 'Ocak',
      february: 'Şubat',
      march: 'Mart',
      april: 'Nisan',
      may: 'Mayıs',
      june: 'Haziran',
      july: 'Temmuz',
      august: 'Ağustos',
      september: 'Eylül',
      october: 'Ekim',
      november: 'Kasım',
      december: 'Aralık',
    },
    receivable: {
      title: 'Alacaklar Raporu',
      business_year: 'İş Yılı',
      total_receivables: 'Toplam Alacaklar',
      total_receivables_customer: 'Toplam Alacaklar Müşteri',
      receivables_products: 'Alacaklar ürünler',
      Maximum_Receivables: 'Maksimum Alacaklar',
    },
    counter: {
      title: 'Pare',
      to_the_cart: 'Sepete Ekle',
      checkout: 'Ödeme',
      search_label: 'Ürünleri Ara',
      search_placeholder: 'ile ürünleri ara',
      product_type_label: 'Ürün Türü',
      categories: 'Kategoriler',
      kitchen: 'Mutfak',
      sell: 'Sat',
      business: 'İşletme',
      store: 'Mağaza',
      starting_selling: 'satışa başlama',
      add_to_cart: 'Sepete Ekle',
      out_of_stock: 'Stoklar tükendi!',
    },
    counter_checkout: {
      title: 'Ödeme',
      order_details: 'Sipariş Detayları',
    },
    order_history: {
      title: 'Sipariş Geçmişi',
      search_label: 'Siparişi Ara',
      search_placeholder: 'Sipariş ID ile Ara',
      refresh: 'Yenile',
      filter: 'Filtre',
      business: 'İşletme',
      store: 'Mağaza',
      accessing_order_history_page: 'sipariş geçmişi sayfasına erişme',
    },
    return: {
      title: 'Sipariş İade #{{order_id}}',
    },
    customers: {
      title: 'Müşteriler',
      search_label: 'Müşterileri Ara',
      search_placeholder: 'Ad, e-posta veya telefon numarası ile ara',
      customer_dropdown_label: 'Referans Müşteri',
      customer_dropdown_no_options_text: 'Referans alınabilir müşteri bulunamadı',
      add_customer: 'Ekle',
      paid: 'Ödenen',
      return: 'İade',
      due: 'Borç',
      reference: 'REFERANS',
      all: 'Tümü',
    },
    products: {
      title: 'Ürünler',
      search_label: 'Ürünleri Ara',
      search_placeholder: 'ile ürünleri ara',
      categories: 'Kategoriler',
      product_type_label: 'Ürün Türü',
      filter_btn_label: 'Filtrele',
      add_product: 'Ürün Ekle',
      business: 'İşletme',
      store: 'Mağaza',
      accessing_product_page: 'Ürünler sayfasına erişme',
    },
    inventory_history: {
      title: 'Envanter Geçmişi',
      filter_btn_label: 'Filtrele',
    },
    inventory_overview: {
      title: 'Envanter Genel Bakış',
      select_inventory: 'Envanter seç',
    },
    category_management: {
      title: 'Kategori Yönetimi',
      add: 'Kategori Ekle',
      toggle: 'Genişletmeyi Değiştir',
      category: 'Kategori',
    },
    purchase_orders: {
      title: 'Tedarik Siparişi',
      mobile_title: 'Satın Alma',
      to_the_cart: 'Sepete Ekle',
      checkout: 'Ödeme',
      search_label: 'Ürünleri Ara',
      search_placeholder: 'ile ürünleri ara',
      stock_status_label: 'Stok Durumu',
      categories: 'Kategoriler',
    },
    purchase_checkout: {
      title: 'Ödeme',
      purchase_details: 'Satın Alma Detayları',
    },
    supply_history: {
      title: 'Satın Alma Sipariş Geçmişi',
      search_label: 'Siparişi Ara',
      search_placeholder: 'Sipariş ID ile Ara',
      filter_btn_label: 'Filtrele',
      business: 'İşletme',
      store: 'Mağaza',
      accessing_supply_history_page: 'tedarik geçmişi sayfasına erişme',
    },
    suppliers: {
      title: 'Tedarikçiler',
      add: 'Ekle',
      search_label: 'Tedarikçi Ara',
      search_placeholder: 'Şirket adı',
    },
    preference: {
      title: 'Tercih',
    },
    profile: {
      title: 'Profil detayları',
    },
    user: {
      title: 'Company Users',
      loading_error,
    },
    roles: {
      title: 'Roller Yönetimi',
      loading_error: 'Yükleme hatası',
    },
    debug: {
      title: 'Hata Ayıklama Bilgisi',
      access_token: 'Erişim Jetonu',
      active_context: {
        title: 'Aktif bağlam',
        company_id: 'Şirket Kimliği',
        store_id: 'Mağaza Kimliği',
      },
      viewport_window: {
        title: 'Görünüm Penceresi',
        width: 'Genişlik',
        height: 'Yükseklik',
      },
      profile_info: {
        title: 'Profil Bilgisi',
        tableTitle: 'Başlık',
        tableValue: 'Değer',
        name: 'Ad',
        email: 'E-posta',
        active: 'Aktif mi',
        type: 'Kullanıcı Türü',
        country: 'Ülke',
        phone: 'Telefon',
        city: 'Şehir',
        gender: 'Cinsiyet',
      },
      sentry_debug: 'Sentry Hata Ayıklama',
      sentry_btn_label: 'Bir sentry hatası ekle',
    },
    companies: {
      title: 'şirketleriniz',
      loading_error: 'Yükleme hatası',
      not_found: 'şirket bulunamadı',
      details_of_company: '{{company_name}} Detayları',
      alert: {
        title: 'Uyarı!',
        description: 'Birden fazla Şirket! Düzenlemek veya kullanmak için bağlamı seçin',
        select_btn_label: 'Bağlamı Seç',
      },
    },
    troubleshooting: {
      title: 'Sorun Giderme',
    },
    context: {
      title: 'Bağlam ayarları',
      heading_title: 'Yönetmek istediğinizi seçin',
      your_companies: 'Şirketleriniz',
      stores: 'Mağazalar',
      search_label: 'Şirketleri Ara',
      search_placeholder: 'Abc Şirketi',
    },
    login: {
      title: 'Giriş',
      welcome_title: "Posinic'e Hoş Geldiniz!",
      description:
        "Posinic ile sorunsuz işlemlere adım atın. POS sistemimizin basitliğini deneyimlemek için şimdi giriş yapın. Posinic'i seçtiğiniz için teşekkür ederiz.",
      contact_us_label: 'Hesabınız yoksa lütfen bizimle iletişime geçin',
      login_btn_label: 'E-posta ile giriş yap',
      request_btn_label: 'Ücretsiz hesap talep et',
    },
    logout: {
      title: 'Çıkış',
    },
  },
  components: {
    //Tr < LoadingScreen />
    loading_screen_label: 'sipariş oluşturuluyor',

    // Tr: <SettingsDrawer />
    // Tr: <SettingsDrawer />
    settings_drawer: {
      dark_light_mode: 'karanlık ışık modu',
      color_presets: 'renk ön ayarları',
      language: 'dil',
    },
    // Tr: <Companies />
    companies: {
      edit: 'düzenle',
      details: 'detaylar',
      add_companies: 'şirket ekle',
    },
    // Tr: <Stores />
    stores: {
      stores: 'Mağazalar',
      edit: 'düzenle',
      details: 'detaylar',
      add_store: 'mağaza ekle',
    },
    // Tr: <Inventories />
    inventories: {
      inventories: 'Envanterler',
      edit: 'düzenle',
      details: 'detaylar',
    },
    // Tr: <User />
    user: {
      add_user: 'davet et',
    },
    // Tr: <Roles />
    roles: {
      add_role: 'Ekle',
    },
    // Tr: <Cart />
    cart: {
      subtotal: 'Ara Toplam',
      total_vat: 'Toplam KDV',
      total_discount: 'Toplam İndirim',
      grand_total: 'Genel Toplam',
      title: 'Sepet',
      no_items_label: 'Sepette öğe yok',
      checkout: 'Ödeme',
    },
    // Tr: <CartItem />
    cart_item: {
      remove: 'Kaldırmak',
      decrease: 'Azaltma',
      increase: 'Arttırmak',
      add_discount: 'İndirim ekle',
    },
    // Tr: <DashboardNumberCard />
    inventory_overview: {
      pur_value_title: 'Envanter Ürün Satın Alma Değeri',
      min_sale_price_title: 'Envanter Minimum Satış Fiyatı',
      reg_sale_price_title: 'Envanter Toplam Düzenli Satış Fiyatı',
    },
    // Tr: <ToggleCardTableView />
    card_table_view: {
      card: 'Kart',
      table: 'Tablo',
    },

    // Tr: <DailyReport />
    daily_report: {
      facts_and_charts: 'Gerçekler ve Grafikler',
      card_view: 'Kart görünümü',
      business_year: 'İş Yılı',
      select_month: 'Ay Seçin',
    },

    // Tr: <FileUpload />
    file_upload: {
      btn_label: 'Resimleri Yükle',
    },

    // Tr: <ProductDetails />
    product_details: {
      variants_select_label: 'Varyantlar',
      disabled_variants_label: 'Devre dışı bırakılanları gizle',
      categories: 'Kategoriler',
      pur_price: 'Satın Alma Fiyatı',
      min_sale: 'Min satış Fiyatı',
      reg_sale: 'Düzenli satış Fiyatı',
      min_sale_mobile: 'Min satış',
      reg_sale_mobile: 'Düzenli satış',
      vat: 'KDV',
      attributes: 'Özellikler',
    },

    // Tr: <CheckoutSidebar />
    supply_checkout_sidebar: {
      title: 'Satın Alma Siparişi Özeti',
      supply_status: 'Tedarik durumu',
      select_payment: 'Ödeme hesabı seçin',
      complete: 'Tamamla',
      subtotal: 'Ara Toplam',
      total_vat: 'Toplam KDV',
      total_discount: 'Toplam İndirim',
      grand_total: 'Genel Toplam',
    },

    // Tr: <VariantItem />
    variant_item: {
      pur_title: 'Satın Alma Fiyatı',
      min_title: 'Minimum Satış Fiyatı (marj)',
      reg_title: 'Düzenli Satış Fiyatı (marj)',
    },

    // Tr: <OrderDetailsPanelReceiver />
    order_details: {
      change_order_status: 'Sipariş durumunu değiştir',
      no_transactions: 'İşlem yok',
      order_items: 'Sipariş Kalemleri',
      transactions: 'İşlemler',
      inventory_history: 'Envanter Geçmişi',
    },
    // Tr: <OrderQueryStateHandler />
    order_query_State_handler: {
      error_alert_title: 'Sipariş getirilirken hata oluştu',
      warning_alert_title:
        'Şu anda yalnızca tam siparişi iade edebilirsiniz, farklı durumlar için lütfen bizimle iletişime geçin',
      select_items: 'Tüm öğeleri seç',
    },
    // Tr: <ReturnOrderItemRow />
    return_order_item: {
      unit_price: 'Birim Fiyat',
      vat: 'KDV',
      net_price: 'Net Fiyat',
      gross_price: 'Brüt Fiyat',
    },
    // Tr: <ReturnInformation />
    return_info: {
      title: 'İade bilgileri',
      return_reason_label: 'İade nedeni',
      refund_info: 'İade bilgisi',
      submit_return: 'İade Gönder',
    },

    // Tr: <OrderItem />
    order_item: {
      unit_price: 'Birim Fiyat',
      vat: 'KDV',
      net_price: 'Net Fiyat',
      gross_price: 'Brüt Fiyat',
      quantity: 'Miktar',
    },
    // Tr: <SupplyItem />
    supply_item: {
      purchase_price: 'Satın Alma Fiyatı',
      quantity: 'Miktar',
    },
    // Tr: <SupplyDetailsPanelReceiver />
    supply_order_details: {
      no_transactions: 'İşlem yok',
      order_items: 'Sipariş Kalemleri',
      transactions: 'İşlemler',
    },

    // Tr: <GlobalMenu />
    global_menu: {
      navigation_menu: 'navigasyon Menüsü',
      toggle_light_Dark: 'Işığını Değiştir',
      change_theme_color: 'Tema rengini değiştir',
    },

    // Tr: <LoadingError />
    loading_error: {
      customer_entity: 'Müşteri',
      transactions_entity: 'İşlemler',
      inventory_history_entity: 'Sipariş Envanter Geçmişi',
      reload_btn_label: 'Yeniden Yükle',
      reports_entity: 'Raporlar',
      coa_entity: 'Hesap Planı',
      stores_entity: 'mağazalar',
    },
  },
  cards: {
    not_found: {
      title: '{{entity}} Bulunamadı',
      type_title: '{{entity}} {{type}} Bulunamadı',
      customer_entity: 'Müşteri',
      transactions_entity: 'İşlemler',
      products_entity: 'ürünler',
      product_entity: 'ürün',
      selected_type: 'seçilen',
      companies_entity: 'şirketler',
      category_entity: 'Kategori',
      stores_entity: 'mağazalar',
      reports_entity: 'Raporlar',
      inventory_history_entity: 'envanter geçmişi',
      company_entity: 'şirket',
      image_entity: 'resim',
      variants_entity: 'varyantlar',
      created_type: 'oluşturulan',
      supply_history_entity: 'tedarik geçmişi',
      users_entity: 'kullanıcılar',
      inventory_entity: 'envanter',
      order_history_entity: 'sipariş geçmişi',
      coa_entity: 'Hesap Planı',
      bank_account_entity: 'banka hesabı',
      categories_entity: 'kategoriler',
    },
    supplier: {
      edit: 'Düzenle',
      orders: 'Siparişler',
      pay_due: 'Borcu Öde',
      added_on: 'Eklenme Tarihi ',
    },
    supply_product: {
      stock_count: 'Stok sayısı',
      variants: 'Varyantlar',
    },
    select_categories: {
      title: 'Kategorileri Seçin',
    },
    category_select: {
      title: 'KÖK',
      description: 'Tüm kategorilerin kök seviyesi',
    },
    category_manage: {},
    report_feed: {
      title: 'Bugünkü Canlı Rapor',
      total_sale_vat: 'Toplam Satış Tutarı (KDV Dahil):',
      total_sale: 'Toplam Satış (KDV hariç):',
      inventory_purchase_price: 'Envanter Satın Alma Fiyatı:',
      net_profit: 'Net Kar',
      payment_methods: 'Ödeme Yöntemleri',
      cash: 'Nakit',
      other_methods: 'Diğer yöntemler',
      receivable: 'Alacaklı',
    },
    customer: {
      change_label: 'değiştir',
      remove_label: 'kaldır',
      joined_on: 'Katılma tarihi',
      pay_due_payment: 'Borcu Öde Ödemesi',
      edit_profile: 'Profili Düzenle',
      reference_details: 'Referans ayrıntıları',
      total_referrals: 'Toplam yönlendirme',
      total_due_amount: 'Toplam vadesi gelen tutar',
      purchase_history: 'Satın Alma Geçmişi',
      due_orders: 'Vadesi Gelen Siparişler',
      reference_label: 'referans',
    },
    customer_receivable: {
      joined_on: 'Katılma tarihi',
    },
    user: {
      joined_on: 'Katılma tarihi',
    },
    price: {
      pur_title: 'Satın Alma Fiyatı',
      min_title: 'Minimum Satış Fiyatı',
      reg_title: 'Düzenli Satış Fiyatı',
      vat_inclusive: '(KDV dahil)',
    },
    order: {
      quantity: 'Miktar',
      total_price: 'Toplam Fiyat',
      seller: 'Satıcı',
      user: 'Kullanıcı',
      created_at: 'Oluşturulma tarihi',
      order_status: 'Sipariş Durumu',
      payment_method: 'Ödeme yöntemi',
      payment_status: 'Ödeme Durumu',
    },
    supply: {
      quantity: 'Miktar',
      total_price: 'Toplam Fiyat',
      seller: 'Satıcı',
      user: 'Kullanıcı',
      created_at: 'Oluşturulma tarihi',
      supply_status: 'Tedarik Durumu',
      payment_status: 'Ödeme Durumu',
      payment_info: 'Ödeme Bilgileri',
    },
    order_payment_info: {
      title: 'Ödeme',
      select_amount_text: 'Ödemeyi tamamlamak için lütfen miktarı ve ödeme yöntemini seçin.',
      full_btn_label: 'Tam',
      wrong_amount: 'yanlış miktar',
      payment_complete: 'Ödeme tamamlandı.',
    },
    customer_info: {
      title: 'Müşteri bilgisi',
      name: 'Ad',
      email: 'E-posta',
      phone: 'Telefon',
      address: 'Adres',
      no_customer_selected: 'Hiçbir müşteri seçilmedi',
    },
    order_information: {
      title: 'Sipariş bilgisi ',
      net_price: ' Net fiyat',
      gross_price: 'Brüt fiyat',
      vat: 'KDV',
      due: 'Borçlu',
      show_invoice: 'Faturayı Göster',
    },
    order_transaction: {
      due: 'Borçlu',
      refunded: 'İade edilen',
      payment: 'Ödeme',
      balance: 'Bakiye',
      date: 'Tarih',
    },
    transaction_compact: {
      expense: 'Gider',
      liability: 'yükümlülük',
      drawing: 'Çekme',
      payment: 'Ödeme',
      amount: 'miktar',
      date: 'Tarih',
      cash_transfer: 'Nakit Transferi',
      created_by: 'Tarafından yaratıldı',
    },
    purchase_order_transaction: {
      due: 'Borçlu',
      refunded: 'İade edilen',
      payment: 'Ödeme',
      balance: 'Bakiye',
      date: 'Tarih',
    },
    //card and table title translation here
    transactions: {
      description: 'Tanım',
      expense: 'gider',
      order: 'emir',
      purchase: 'satın almak',
      liability: 'yükümlülük',
      transfer: 'Aktar',
      drawing: 'çizim',
      invest: 'yatırım',
      due_payment: 'vadesi gelen ödeme',
      transaction_date: 'İşlem Tarihi',
      created_at: 'Oluşturulma Tarihi',
      account: 'Hesap',
      debit: 'Borç',
      credit: 'Kredi',
      balance: 'Denge',
      amount: 'Miktar',
      edit: 'Düzenlemek',
    },
    chart_of_account: {
      default: 'varsayılan',
      asset: 'varlık',
      liability: 'borç',
      revenue: 'gelir',
      expense: 'gider',
      drawing: 'Çekme',
      edit_label: 'Bilgileri Düzenle',
      register_label: 'Kayıt',
    },
    company: {},
    counter_product: {
      variants: 'Varyantlar',
    },
    inventory_compact: {
      linked: 'Bağlantılı',
    },
    inventory_details: {
      associated_stores: 'İlişkilendirilmiş Mağazalar',
    },
    store_details: {
      payment_method: 'Ödeme yöntemi',
    },
    variant: {
      pur_price: 'Satın Alma Fiyatı',
      min_price: 'Min. Fiyat',
      reg_price: 'Düzenli Fiyat',
      attributes: 'Özellikler',
      edit_info: 'Bilgileri Düzenle',
      duplicate: 'Çoğalt',
      chip_label: 'Pasif',
    },
    supply_info: {
      title: 'Özet',
      pur_price: 'Satın alma fiyatı',
    },
    supplier_info: {
      title: 'Tedarikçi bilgisi',
      name: 'Ad',
      email: 'E-posta',
      phone: 'Telefon',
      address: 'Adres',
      no_customer_selected: 'Hiçbir müşteri seçilmedi',
    },
    purchase_order_payment_info: {
      title: 'Ödeme',
      select_payment_account_text: 'Ödemeyi tamamlamak için lütfen miktarı ve ödeme yöntemini seçin.',
      full_btn_label: 'Tam',
      pay_btn_label: 'Öde',
      wrong_amount: 'Yanlış miktar',
      payment_complete: 'Ödeme tamamlandı.',
    },
  },
  dialogs: {
    context_menu_title: 'Bir seçenek seçin',
    category_title: '{{title_mode}} Kategori Diyaloğu',
    chart_of_account_title: '{{title_mode}} Hesap Tablosu Diyaloğu',
    customer_title: '{{title_mode}} Müşteri Diyaloğu',
    inventory_title: '{{title_mode}} Envanter Diyaloğu',
    store_title: '{{title_mode}} Mağaza Diyaloğu',
    transaction_title: '{{title_mode}} İşlem Diyaloğu',
    user_title: '{{title_mode}} Kullanıcı Diyaloğu',
    add_product_inventory_title: 'Envanter Diyaloğuna Ürün Ekle',
    company: { title: '{{title_mode}} Şirket Diyaloğu', upload_logo: 'Logoyu Yükle' },
    variant_select: {
      title: '{{title}} varyantını seçin',
      alert_label:
        '  Ürün şu anda atanmış bir tedarikçiye sahip değil. Lütfen mevcut listeden seçin veya yeni bir tedarikçi ekleyin',
      add_supplier: 'yeni tedarikçi ekle',
      suppliers_select_label: 'Tedarikçi',
      no_supplier_label: 'Tedarikçi yok',
      no_product_label: 'Seçili ürün yok',
      select_supplier_title: 'Tedarikçi seç',
      select_supplier_text: 'Ürün eklemek için önce bir tedarikçi seçmelisiniz',
      add_purchase_order: 'Satın Alma Siparişi Ekle',
    },
    prices_adjust: {
      title: 'Fiyatları Ayarla',
      input_error: 'giriş hatası',
    },
    add_discount: {
      title: 'Birim Başına İndirim Ekle',
      error_input_label: 'HATA: lütfen girişinizi kontrol edin',
      wrong_input_label: 'HATA: yanlış giriş, sınırı aşabilir!',
      amount: 'Miktar',
      percent: 'Yüzde',
      max_discount: 'Maks. İndirim',
      discount: 'İndirim',
      final_price: 'Son Fiyat',
      add_discount: 'İndirim Ekle',
    },
    cash_payment: {
      title: 'Nakit Ödeme Diyaloğu',
      input_error_label: 'giriş hatası, lütfen girişinizi kontrol edin',
      summery: 'Özet',
      select_customer: 'Bir müşteri seçin',
      complete: 'Tamamla',
      paid: 'Ödenen',
      return: 'Geri dön',
      remaining_due: 'Hala ödenmesi gereken',
      remaining_due_customer: 'Hala ödenmesi gereken (bir müşteri seçin)',
      send_invoice_label: 'müşteriye fatura gönder',
      send_invoice_customer_label: 'Fatura gönderilecek müşteriyi seçin',
      order_discount: 'Toplam Sipariş İndirimi',
      subtotal: 'Ara Toplam',
      total_vat: 'Toplam KDV',
      total_discount: 'Toplam İndirim',
      grand_total: 'Genel Toplam',
      no_email: 'E-posta yok',
    },
    select_products: {
      title: 'Ürünleri Seç Diyaloğu',
      select_btn_label: 'Ürünleri Seç',
    },
    role: {
      title: '{{title_mode}} Rol Diyaloğu',
      import_existing_role: 'mevcut rolü içeri aktar',
    },
    select_role: {
      title: 'mevcut rol yapılandırmasını içeri aktar',
      click_import_label: 'içeri aktarılacak bir rolü tıklayın/dokunun ',
      alert_label: 'Kullanıcı Yükleme Hatası',
    },
    select_categories: {
      title: '{{title_mode}} Kategoriler Diyaloğu',
      select_btn_label: 'Kategorileri Seç',
      alert_label: 'kategori bulunamadı',
    },
    select_customer: {
      title: 'Müşteri Seç',
      search_label: 'Müşteri Ara',
      search_placeholder: 'İsim, e-posta veya telefon numarası ile ara',
      add_customer: '{{text}} Müşteri Ekle',
    },
    product: {
      title: '{{title_mode}} Bir Ürün Diyaloğu',
      categories_step_label: 'Kategori seçimi',
      variants_step_label: 'Varyant Bilgileri',
      product_step_label: 'Ürün Bilgileri',
      summery_step_label: 'Özet',
      save_btn_label: '{{title_mode}} Ürünü Kaydet',
    },
    variant: {
      title: '{{title_mode}} ürün varyant Diyaloğu',
      add_btn_label: '{{title_mode}} varyant',
      add_barcode: 'Barkod Ekle',
      scan_btn_label: 'Tara',
    },
    supplier: {
      title: '{{title_mode}} Bir Tedarikçi Diyaloğu',
      supplier_products_label: ' Tedarikçi Ürünleri',
      company_step_label: 'Bilgi',
      product_step_label: 'Ürünler',
      summery_step_label: 'Özet',
      add_product_btn_label: '{{title_mode}} Ürün',
      save_btn_label: 'Tedarikçiyi Kaydet',
      contact_information: 'İletişim Bilgileri',
      products: 'Ürünler',
    },
    register_expense: {
      title: 'Harcamanızı kaydedin',
      expense: 'gider',
      liability: 'borç',
      amount: 'Miktar',
      save_btn_label: 'İşlemi Kaydet',
      transaction_info: 'İşlem Bilgisi',
      input_error_label: 'giriş hatası, lütfen girişinizi kontrol edin',
      'account-helper-text': 'Bu, ödeme yaptığınız hesaptır',
      'step-1-instruction': 'Hangi tür harcamayı kaydetmek istediğinizi seçin',
      'step-2-instruction': 'işlem bilgisi',
    },
    register_drawing: {
      title: 'Çiziminizi Kaydedin',
      save_btn_label: 'İşlemi Kaydet',
      transaction_info: 'İşlem Bilgisi',
      drawing: 'Çekme',
      amount: 'Miktar',
      input_error_label: 'giriş hatası, lütfen girişinizi kontrol edin',
      'account-helper-text': 'Bu, ödeme yaptığınız hesaptır',
      'step-1-instruction': 'para çekme hesabınızı seçin',
      'step-2-instruction': 'işlem bilgisi',
    },
    cash_transfer: {
      title: 'Nakit Transferini Kaydet',
      amount: 'Miktar',
      input_error_label: 'giriş hatası, lütfen girişinizi kontrol edin',
      amount_error_label: '{{coa_name}} giriş miktarından fazla giriş miktarı',
      balance_zero_error_label: '{{coa_name}} hesap bakiyesi sıfır',
      save_btn_label: 'İşlemi Kaydet',
      no_balance: 'denge yok',
    },
    update_number: {
      input_error_label: 'giriş hatası, lütfen girişinizi kontrol edin',
      quantity_error_label: 'giriş numarası stok miktarından fazla',
    },
    invoice_details: {
      title: 'Fatura detayları',
      purchase_date: 'Satın Alma Tarihi',
      order: 'Sipariş',
      payment_status: 'Ödeme Durumu',
      order_status: 'Sipariş Durumu',
      unit_price: 'Birim fiyat',
      table_cell_title: 'Başlık',
      table_cell_unit_price: 'Birim Fiyat',
      table_cell_vat: 'KDV',
      table_cell_discount: 'İndirim',
      table_cell_quantity: 'Miktar',
      table_cell_price: 'Fiyat,',
      footer_title: 'NOTLAR',
      footer_description: 'Bu fatura ile oluşturulmuştur',
      footer_description_link: 'Posinic POS Sistemi',
      have_question: 'Sorunuz mu var?',
      paid: 'Ödenen',
      due: 'Vadesi',
      subtotal: 'Ara Toplam',
      total_vat: 'Toplam KDV',
      total_discount: 'Toplam İndirim',
      grand_total: 'Genel Toplam',
    },
    invoice_share_details: {
      title: 'Paylaşmak',
      send_email: 'E-posta Gönder',
      send_invoice_title: 'Sipariş faturasını gönder',
      email_label: 'E-posta Adresi',
      whatsapp: 'Whatsapp',
      email: 'E-posta',
    },
  },
  forms: {
    // forms react components and validation schemas both would be here
    email_submit: {
      email_label: 'E-postanızla giriş yapın',
      no_account_found: 'Hesap bulunamadı!',
    },
    otp_submit: {
      request_new_code: 'Yeni kod iste',
      try_another_email: ' Başka bir e-posta deneyin',
    },
    transaction: {
      debit_account_label: 'Borç hesabı',
      credit_account_label: 'Kredi Hesabı',
      description_label: 'Açıklama',
      description_placeholder: "Bu hesap SCB Kualampur'un altındadır. Bay John Doe hesap yöneticisidir.",
      amount_label: 'Bakiye',
      amount_placeholder: '100.00',
      transaction_date_label: 'İşlem Tarihi',
    },
    address_fields: {
      road_label: 'Cadde',
      road_placeholder: 'Reginhardstraße',
      house_number_label: 'Ev Numarası',
      house_number_placeholder: '65',
      zip_code_label: 'Posta Kodu',
      zip_code_placeholder: '13409',
      city_label: 'Şehir',
      city_placeholder: 'Berlin',
      country_label: 'Ülke',
    },
    bar_code: {
      label: 'Barkod',
    },
    category: {
      title_label: 'Kategori Adı',
      title_placeholder: 'T-Shirt',
      description_label: 'Açıklama',
      description_placeholder: 'Kategori detayları',
    },
    chart_of_account: {
      account_name_label: 'Hesap Adı',
      account_name_placeholder: 'SCB Kualampur #48270',
      account_type_label: 'Hesap Türü',
      account_type_placeholder: 'Varlık',
      description_label: 'Açıklama',
      description_placeholder: "Bu hesap SCB Kualampur'un altındadır. Bay John Doe hesap yöneticisidir.",
      active_label: 'Hesabı etkinleştirmek istiyor musunuz?',
      payment_possible_label: 'Bu hesabı ödemeler için kullanmak istiyor musunuz?',
      cash_account_label: 'Bu hesabı nakit hesap olarak mı kullanmak istiyorsunuz?',
    },
    expanse_transaction: {
      select_account_label: 'Ödeme yapmak istediğiniz hesap',
      description_label: 'Açıklama',
      description_placeholder: "Bu hesap SCB Kualampur'un altındadır. Bay John Doe hesap yöneticisidir.",
      balance_zero_error_label: '{{coa_name}} account balance zero',
      no_balance: 'denge yok',
    },
    drawing_transaction: {
      select_account_label: 'Ödeme yapmak istediğiniz hesap',
      description_label: 'Açıklama',
      description_placeholder: 'this account is under SCB Kualampur. Mr John Doe is the account manager.',
      balance_zero_error_label: '{{coa_name}} account balance zero',
      no_balance: 'denge yok',
    },
    company: {
      title_label: 'Şirket Adı',
      title_placeholder: 'Panshilab',
      currency_label: 'Para Birimi',
    },
    customer: {
      first_name_label: 'Adı',
      first_name_placeholder: 'John',
      last_name_label: 'Soyadı',
      last_name_placeholder: 'Doe',
      email_label: 'E-posta',
      email_placeholder: 'ornek@domain.com',
      phone_number_label: 'Telefon Numarası',
      phone_number_placeholder: '01740837450',
      reference_id: 'Referans Numarası',
      reference_label: 'Müşteri Referansı',
      is_referable_label: 'Referans olarak kullan?',
      is_referable_helperText: 'Referans özelliğini yalnızca kullanımda değilse kaldırabilirsiniz',
      reference_dropdown_no_options_text: 'Müşteri bulunamadı',
      description_label: 'Açıklama',
      description_placeholder: 'Müşteri açıklaması buraya',
    },
    fieldset: {
      user_info_title: 'Kişisel bilgiler',
      assignments_and_permissions_title: 'Atamalar ve izinler',
      address_info_title: 'Adres',
      chart_of_account_title: 'Hesap Tablosu Detayları',
      company_title: 'Şirket Bilgileri',
      customer_title: 'Kişisel Bilgiler',
      permissions_title: 'İzinler',
      role_title: 'Rol bilgisi',
      store_title: 'Mağaza Bilgileri',
      supplier_title: 'Şirket Bilgileri',
      core_info_title: 'Temel Bilgiler',
      attributes_info_title: 'Özellikler',
      images_info_title: 'Görüntüler',
      barcode_info_title: 'Barkodlar',
    },
    inventory: {
      title_label: 'Envanter Adı',
      title_placeholder: 'Berlin Envanteri',
    },
    owner_details: {
      first_name_label: 'Adı',
      first_name_placeholder: 'John',
      last_name_label: 'Soyadı',
      last_name_placeholder: 'Doe',
      phone_number_label: 'Telefon Numarası',
      phone_number_placeholder: '01740837450',
      city_label: 'Şehir',
      city_placeholder: 'Berlin',
      country_label: 'Ülke',
    },
    payment_method: {
      label: 'Yöntem',
      add_btn_label: 'Yöntem Ekle',
    },
    product: {
      title_label: 'Başlık',
      title_placeholder: 'Ürün Adı',
      unit_label: 'Birim',
      vat_label: 'KDV',
      vat_placeholder: '10',
      product_type_label: 'Ürün tipini seçin',
      description_label: 'Açıklama',
      description_placeholder: 'Ürün açıklaması buraya',
    },
    roles: {
      title_label: 'Başlık',
      title_placeholder: 'Yönetici',
      description_label: 'Açıklama',
      description_placeholder: 'Rol açıklaması buraya',
    },
    store: {
      title_label: 'Mağaza Adı',
      title_placeholder: 'Berlin Mağazası',
    },
    supplier: {
      title_label: 'Şirket Adı',
      title_placeholder: 'Panshilab',
      email_label: 'E-posta',
      email_placeholder: 'ornek@domain.com',
      phone_number_label: 'Telefon Numarası',
      phone_number_placeholder: '01740837450',
    },
    user: {
      first_name_label: 'Ad',
      first_name_placeholder: 'John',
      last_name_label: 'Soyadı',
      last_name_placeholder: 'Doe',
      email_label: 'E-posta',
      email_placeholder: 'ornek@domain.com',
      phone_number_label: 'Telefon Numarası',
      phone_number_placeholder: '01740837450',
      role_label: 'Rol',
      assigned_stores_label: 'Atanan Mağazalar',
      assigned_inventories_label: 'Atanan Envanterler',
      staff_btn_label: 'PERSONEL',
      partner_btn_label: 'ORTAK',
    },
    variant: {
      title_label: 'Başlık',
      title_placeholder: 'Ör: iPhone 15 128 GB',
      stock_threshold_label: 'Düşük Stok Eşiği',
      stock_threshold_placeholder: '10',
      ser_sale_price_label: 'Hazırlık Maliyeti',
      pur_sale_price_label: 'Satış Fiyatı',
      pur_sale_price_placeholder: 'Satış Fiyatı',
      min_sale_price_label: 'Minimum Satış Fiyatı',
      min_sale_price_placeholder: 'Minimum Satış Fiyatı',
      reg_sale_price_label: 'Normal Satış Fiyatı',
      reg_sale_price_placeholder: 'Normal Satış Fiyatı',
      background_mode_label: 'Arka Plan Modu',
    },
    attribute: {
      title_label: 'Başlık',
      title_placeholder: 'Başlık',
      title_helper_text: 'Özellik başlığı gereklidir',
      title_exist_helper_text: 'Zaten mevcut',
      value_label: 'Değer',
      value_placeholder: 'Değer',
      value_helper_text: 'Özellik değeri gereklidir',
    },
  },
  filter: {
    sale_reports: {
      ...common_filter,
      text: 'Özel satış raporunu görüntülemek için filtreleri seçin. Varsayılan filtreleri ayarlamak için sıfırla düğmesine tıklayın.',
      business_year: 'İşletme Yılı',
    },
    order_history: {
      ...common_filter,
      text: 'Belirli bir sipariş geçmişini görüntülemek için filtreleri seçin. Tüm filtreleri temizlemek için temizle düğmesine tıklayın.',
      order_status: 'Sipariş durumu',
      payment_status: 'Ödeme durumu',
      seller: 'Satıcı',
      customer: 'Müşteri',
    },
    products: {
      ...common_filter,
      text: 'Belirli bir envanter ürününü görüntülemek için filtreleri seçin. Tüm filtreleri temizlemek için temizle düğmesine tıklayın.',
      inventory: 'Envanter',
      stock_status: 'Stok durumu',
    },
    inventory_history: {
      ...common_filter,
      text: 'Belirli bir envanter geçmişini görüntülemek için filtreleri seçin. Tüm filtreleri temizlemek için temizle düğmesine tıklayın.',
      reason: 'Neden',
      inventory: 'Envanter',
    },
    supply_history: {
      ...common_filter,
      text: 'Belirli bir satın alma siparişi geçmişini görüntülemek için filtreleri seçin. Tüm filtreleri temizlemek için temizle düğmesine tıklayın.',
      supply_status: 'Tedarik Durumu',
      payment_status: 'Ödeme Durumu',
      receiver: 'Alıcı',
      supplier: 'Tedarikçi',
    },
  },
  snackbar: {
    token_copy: 'Metin panonuza kopyalandı.',
    invoice_link_copy: 'Fatura bağlantısı başarıyla kopyalandı.',
    discount_applied_successfully: 'İndirim başarıyla uygulandı!',
  },
  navigation: {
    dashboard: 'Gösterge Paneli',
    chart_of_accounts: 'Hesap Tablosu',
    register: 'Kayıt',
    transactions: 'İşlemler',
    expense: 'Ödeme / Gider',
    drawing: 'Çizim',
    cash_transfer: 'Nakit Transferi',
    sale: 'Satış',
    daily: 'Günlük',
    monthly: 'Aylık',
    yearly: 'Yıllık',
    profit_and_loss: 'Kar ve Zarar',
    purchase: 'Satın Alma',
    customer_due: 'Müşteri Vadesi',
    payable: 'Ödenecek',
    return: 'İade',
    history: 'Geçmiş',
    invoice: 'Fatura',
    customers: 'Müşteriler',
    company: 'Şirket',
    user: 'Kullanıcı',
    profile: 'Profil',
    roles: 'Roller',
    preference: 'Tercihler',
    debug: 'Hata Ayıklama',
    context: 'Bağlam',
    troubleshooting: 'Sorun Giderme',
    logout: 'Çıkış Yap',
    purchase_order: 'Satın Alma Siparişi',
    products: 'Ürünler',
    category_management: 'Kategori Yönetimi',
    overview: 'Genel Bakış',
    checkout: 'Ödeme',
    suppliers: 'Tedarikçiler',
    settings: 'Ayarlar',
    accounting: 'Muhasebe',
    reports: 'Raporlar',
    counter: 'Sayaç',
    order: 'Sipariş',
    inventory: 'Envanter',
    supply: 'Tedarik',
    supplier: 'Tedarikçi',
    operations: 'Operasyonlar',
    companies: 'Şirketler',
    customer: 'Müşteri',
    loading_error: 'Yükleme Hatası',
    reload_app: 'Uygulamayı Yeniden Yükle',
    reset_app: 'Uygulamayı Sıfırla',
    menu: 'Menü',
  },
  paymentMethods: {
    card: 'Kart',
    cash: 'Nakit',
    receivable: 'Alacak',
  },
  user: 'kullanıcı',
  companies: 'şirketler',
  profile: 'profil',
  preference: 'tema tercihi',
  debug: 'hata ayıklama',
  context: 'bağlam',
  lock: 'Uygulamayı Kilitle',
  settings: 'ayarlar',
  roles: 'roller',
  error: 'hata',
  checkout: {
    add_order_discount: 'Sipariş İndirimi Ekle',
    complete: 'Tamamla',
    grand_total: 'Toplam Tutar',
    order_discount: 'sipariş indirimi',
    pay: 'Öde',
    reset_order_discount: 'indirimi kaldır',
    select_customer: 'Müşteri Seçin',
    send_kitchen: "Mutfak'a Gönder",
    subtotal: 'Ara Toplam',
    title: 'Sipariş Özeti',
    total_discount: 'Toplam İndirim (KDV Dahil)',
    total_vat: 'Toplam KDV',
    update_order_discount: 'Sipariş İndirimini Güncelle',
    payment_methods: {
      receivable_warning: 'Alacaklı ödemesi yapmak için bir müşteri seçin',
      card: 'Kart',
      cash: 'Nakit',
      receivable: 'Alacak',
    },
  },
  tooltip: {
    dialog_disabled_step_next_btn_title: 'Lütfen ilk adımları tamamlayın',
    download_title: 'İndir',
    share_title: 'Paylaş',
    show_hide_category_title: 'Bu kategorinin alt kategorilerini göster/gizle',
    edit_category_title: 'Bu kategoriyi düzenle',
    move_category_title: 'Başka bir üst kategori altına taşı',
    copy_category_title: 'Bu kategoriyi kopyala',
    add_child_category_title: 'Bu kategorinin alt kategorisi ekle',
  },
  alert: {
    handle_no_stock: {
      title: 'Stok Mevcut Değil!',
      description: 'Sepetteki ürün miktarı stok miktarından fazla',
    },
    dialog_incomplete_step: {
      title: 'Eksik form!',
      description: 'Bu adıma erişilemiyor',
    },
    not_editable_role: {
      success_btn_text: 'Anladım',
      title: 'Düzenlenemez',
      description:
        'Varsayılan roller düzenlenemez, kendi rollerinizi özelleştirmek için yeni roller oluşturabilirsiniz',
    },
    multiple_companies: {
      title: 'Uyarı!',
      description: 'Birden fazla Şirket! Düzenlemek veya kullanmak için bağlamda seçin',
      select_btn_label: 'Bağlamı Seç',
    },
    successfully_logout: {
      title: 'Başarıyla çıkış yaptınız',
      description: 'Harika işler üzerinde çalışmaya devam etmek için tekrar giriş yapın!',
      login_btn_label: 'Giriş Yap',
    },
    otp_failed: {
      title: 'OTP Başarısız!',
      description: 'Lütfen OTP kodunuzu tekrar kontrol edin.',
    },
    otp_success: {
      title: 'Giriş kodu için e-postanızı kontrol edin',
      description: 'E-posta almadıysanız lütfen spam klasörünü kontrol edin. Ayrıca yeni bir kod isteyebilirsiniz',
    },
    handle_set_context: {
      title: '{{title}} seçmeniz gerekiyor, önce {{description}}',
      description: '{{title}} seçmek ister misiniz?',
      select_btn_label: '{{title}} Seç',
    },
    partially_returned: {
      title: 'Uyarı!',
      description: 'Kısmen iade edilmiş sipariş tekrar iade edilemez',
      confirm_btn_text: 'Anladım',
    },
    already_returned: {
      title: 'Uyarı!',
      description: 'Zaten iade edilmiş sipariş tekrar iade edilemez',
      confirm_btn_text: 'Anladım',
    },
    partially_paid: {
      title: 'Uyarı!',
      description: 'Kısmen ödenmiş sipariş şu anda iade edilemez. Yardım almak için lütfen destek ile iletişime geçin',
      confirm_btn_text: 'Anladım',
    },
  },
  confirm: {
    troubleshooting: {
      title: 'Emin misiniz?',
      description: 'Kaydedilmemiş tüm verileriniz kaybolacak!',
    },
    debug: {
      title: 'Onaylıyor musunuz?',
      description: 'Sentry kontrolü için bir hata oluşturmak istediğinizden emin misiniz?',
    },
    add_category_management: {
      title: 'Ürünleri Yeni Kategoriye Taşımak İstediğinizden Emin misiniz?',
      description:
        'Mevcut ana kategoriye atanan ürünler bulunmaktadır. Bu işlem, ana kategorinin mevcut ürünlerini yeni oluşturulan kategori altına taşıyacaktır.',
    },
    variants: {
      title: 'Kopyalamak istediğinizden emin misiniz?',
      description: 'Mevcut varyantı kopyalamak istediğinizden emin misiniz?',
    },
    order_success: {
      title: 'Başarılı!',
      description: 'Satın Alma Siparişi oluşturma başarılı',
      confirm_btn_text: 'Sayaça',
      deny_btn_text: 'Faturayı Göster',
    },
    order_error: {
      title: 'Bir şeyler yanlış',
      description: 'Sipariş oluşturma başarısız oldu!',
      confirm_btn_text: 'Tamam, Tekrar Dene',
      deny_btn_text: 'Faturayı Göster',
    },
    order_timeout: {
      title: 'Süre aşımı!',
      description:
        'Sipariş oluşturma zaman aşımına uğradı, sipariş muhtemelen başarıyla oluşturulmuştur ancak ağ kesintisi nedeniyle onaylanmamış olabilir, yeni bir sipariş oluşturmadan önce sipariş geçmişini kontrol edin.',
      confirm_btn_text: 'Sipariş Geçmişine',
    },
    send_to_kitchen: {
      success_title: 'Başarılı!',
      error_title: 'Bir şeyler yanlış',
      success_description: 'Mutfaka gönderildi',
      error_description: 'Mutfaka gönderme başarısız oldu',
      success_btn_text: 'Sayaça',
      error_btn_text: 'Tamam, Tekrar Dene',
    },
    submit_due_payment: {
      title: 'Vadesi Geçmiş Ödeme Yapılsın mı?',
      description: '{{name}} kullanıcısı için vadesi geçmiş ödeme eklemek istediğinizden emin misiniz?',
    },
    submit_return: {
      title: 'Emin misiniz?',
      description: 'Bu öğeleri iade etmek istediğinizden emin misiniz?',
    },
    mark_as_completed: {
      title: 'Tamamlandı olarak işaretle?',
      description: 'Bu siparişi tamamlandı olarak işaretlemek istediğinizden emin misiniz?',
    },
    mark_as_pending: {
      title: 'Beklemede olarak işaretle?',
      description: 'Bu siparişi beklemede olarak işaretlemek istediğinizden emin misiniz?',
    },
    mark_as_refunded: {
      title: 'İade Edildi olarak işaretle?',
      description: 'Bu siparişi iade edildi olarak işaretlemek istediğinizden emin misiniz?',
    },
    mark_as_cancelled: {
      title: 'İptal Edildi olarak işaretle?',
      description: 'Bu siparişi iptal edildi olarak işaretlemek istediğinizden emin misiniz?',
    },
    mark_as_pending_delivery: {
      title: 'Teslimat Beklemede olarak işaretle?',
      description: 'Bu siparişi teslimat beklemekte olarak işaretlemek istediğinizden emin misiniz?',
    },
    mark_as_delivery_in_progress: {
      title: 'Teslimat Sürecinde olarak işaretle?',
      description: 'Bu siparişi teslimat sürecinde olarak işaretlemek istediğinizden emin misiniz?',
    },
    mark_as_delivered: {
      title: 'Teslim Edildi olarak işaretle?',
      description: 'Bu siparişi teslim edildi olarak işaretlemek istediğinizden emin misiniz?',
    },
    process_payment_handler: {
      title: 'Ödeme yapmak istiyor musunuz?',
      description: 'Ödeme yapmak istediğinizden emin misiniz?',
    },
    delete_order_item: {
      title: 'Öğeyi silmek istiyor musunuz?',
      description: 'Sipariş öğesini silmek istediğinizden emin misiniz?',
    },
    logout_alert: {
      title: 'Çıkış Onayı?',
      description: 'Çıkış yapmak istediğinizden emin misiniz?',
    },
  },
  swal: {
    agree: 'Evet, Kabul Ediyorum',
    cancel: 'İptal',
  },
};
