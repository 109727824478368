const loading_error = 'লোড করতে সমস্যা হয়েছে, দয়া করে আবার চেষ্টা করুন।';
const common_filter = {
  from: 'থেকে',
  to: 'পর্যন্ত',
  time_range: 'সময় পরিসীমা',
  title: 'ফিল্টার প্যানেল',
};

export const bn = {
  common: {
    cancel_btn_label: 'বাতিল করুন',
    clear_btn_label: 'মুছে ফেলুন',
    close_btn_label: 'বন্ধ করুন',
    reset_btn_label: 'রিসেট',
    save_btn_label: 'পরিবর্তনগুলোর সংরক্ষন করুন',
    save: 'সংরক্ষণ',
    update: 'আপডেট',
    select: 'নির্বাচন করুন',
    add: 'যোগ করুন',
    details: 'বিস্তারিত',
    edit: 'পরিবর্তন',
    new: 'নতুন',
    change: 'পরিবর্তন',
  },
  pages: {
    accounting: {
      title: 'চার্ট অব অ্যাকাউন্টস্',
      accounting: 'অ্যাকাউন্টিং',
    },
    inventory: {
      title: 'ইনভেন্টরি',
    },
    company_details: {
      settings: 'সেটিংস',
      supplier: 'সরবরাহকারী',
      reports: 'রিপোর্ট',
      order: 'অর্ডার',
    },
    chart_of_accounts: {
      title: 'চার্ট অব অ্যাকাউন্টস্',
      asset: 'সম্পদ',
      expense: 'খরচ',
      liability: 'দায়',
      revenue: 'রাজস্ব',
      drawing: 'উত্তোলন',
      add_account: 'নতুন অ্যাকাউন্ট যোগ করুন',
      account_type: 'অ্যাকাউন্টের ধরন',
      all: 'সব',
      alert: {
        title: 'ত্রুটি',
        text: 'অনুরোধ ব্যর্থ',
        strong_text: 'রিফ্রেশ করুন',
      },
    },
    register: {
      head_title: 'লেনদেন',
      title: 'রেজিস্টার',
      card: 'কার্ড',
      table: 'টেবিল',
      add_register: 'নতুন',
    },
    expense: {
      title: 'পেমেন্ট / খরচ',
      add_expense: 'একটি খরচ নিবন্ধন করুন',
    },
    drawing: {
      title: 'উত্তোলন',
      add_drawing: 'একটি উত্তোলন নিবন্ধন করুন',
    },
    cash_transfer: { title: 'ক্যাশ ট্রান্সফার', add_transfer: 'রেজিস্টার ক্যাশ ট্রান্সফার' },
    register_id: {
      title: 'লেনদেন',
      add_register: 'একটি লেনদেন নিবন্ধন করুন',
    },
    sale: {
      daily_title: 'দৈনিক বিক্রয় রিপোর্ট',
      monthly_title: 'মাসিক বিক্রয় রিপোর্ট',
      today_live_report: 'আজকের লাইভ রিপোর্ট',
      store_dropdown_label: 'স্টোর',
      daily: 'দৈনিক',
      monthly: 'মাসিক',
      yearly: 'বাৎসরিক',
    },
    purchase: {
      title: 'ক্রয় প্রতিবেদন',
      business_year: 'ব্যবসায়িক বছর',
      total_purchase: 'মোট ক্রয়',
      purchase_payable: 'প্রদেয় সঙ্গে ক্রয়',
      january: 'জানুয়ারি',
      february: 'ফেব্রুয়ারি',
      march: 'মার্চ',
      april: 'এপ্রিল',
      may: 'মে',
      june: 'জুন',
      july: 'জুলাই',
      august: 'আগস্ট',
      september: 'সেপ্টেম্বর',
      october: 'অক্টোবর',
      november: 'নভেম্বর',
      december: 'ডিসেম্বর',
    },
    receivable: {
      title: 'প্রাপ্য রিপোর্ট',
      business_year: 'ব্যবসায়িক বছর',
      total_receivables: 'মোট প্রাপ্য',
      total_receivables_customer: 'মোট প্রাপ্য গ্রাহক',
      receivables_products: 'প্রাপ্য পণ্য',
      Maximum_Receivables: 'সর্বোচ্চ প্রাপ্য',
    },
    counter: {
      title: 'কাউন্টার',
      to_the_cart: 'কার্টে যান',
      checkout: 'চেকআউট',
      search_label: 'পণ্য খুঁজুন',
      search_placeholder: 'পণ্য অনুসন্ধান করুন',
      product_type_label: 'পণ্যের ধরন',
      categories: 'ক্যাটাগরি',
      kitchen: 'রান্নাঘর',
      sell: 'বিক্রয়',
      business: 'ব্যবসা',
      store: 'দোকান',
      starting_selling: 'বিক্রয় শুরু',
      add_to_cart: 'কার্টে যোগ করুন',
      out_of_stock: 'মজুত শেষ!',
    },
    counter_checkout: {
      title: 'চেকআউট',
      order_details: 'অর্ডার বিস্তারিত',
    },
    order_history: {
      title: 'অর্ডার হিস্টরি',
      search_label: 'অর্ডার খুঁজুন',
      search_placeholder: 'সার্চ অর্ডার আইডি',
      refresh: 'রিফ্রেশ',
      filter: 'ফিল্টার',
      business: 'ব্যবসা',
      store: 'দোকান',
      accessing_order_history_page: 'অর্ডার হিস্টরি পৃষ্ঠা অ্যাক্সেস করা',
    },
    return: {
      title: 'রিটার্ন অর্ডার #{{order_id}}',
    },
    customers: {
      title: 'ক্রেতাসমূহ',
      search_label: 'ক্রেতা খুঁজুন',
      search_placeholder: 'নাম ইমেল বা ফোন নম্বর দিয়ে খুঁজুন',
      customer_dropdown_label: 'রেফারেন্স ক্রেতা',
      customer_dropdown_no_options_text: 'কোন উল্লেখযোগ্য ক্রেতা পাওয়া যায়নি',
      add_customer: 'নতুন',
      paid: 'প্রদান',
      due: 'বাকি',
      reference: 'রেফারেন্স',
      all: 'সব',
    },
    products: {
      title: 'প্রোডাক্ট',
      search_label: 'পণ্য খুঁজুন',
      search_placeholder: 'পণ্য খুঁজুন করুন',
      categories: 'ক্যাটাগরি',
      product_type_label: 'পণ্যের ধরন',
      filter_btn_label: 'ফিল্টার',
      add_product: 'নতুন পণ্য তৈরি করুন',
      business: 'ব্যবসা',
      store: 'দোকান',
      accessing_product_page: 'প্রোডাক্ট পৃষ্ঠা অ্যাক্সেস করা',
    },
    inventory_history: {
      title: 'ইনভেন্টরি হিস্টরি',
      filter_btn_label: 'ফিল্টার',
    },
    inventory_overview: {
      title: 'ইনভেন্টরি ওভারভিউ',
      select_inventory: 'ইনভেন্টরি নির্বাচন করুন',
    },
    category_management: {
      title: 'ক্যাটাগরি ম্যানেজমেন্ট',
      add: 'নতুন ক্যাটাগরি বানান',
      toggle: 'টগল প্রসারিত করুন',
      category: 'ক্যাটাগরি',
    },
    purchase_orders: {
      title: 'ক্রয় অর্ডার',
      mobile_title: 'ক্রয়',
      to_the_cart: 'কার্টে যান',
      checkout: 'চেকআউট',
      search_label: 'পণ্য খুঁজুন',
      search_placeholder: 'পণ্য খুঁজুন',
      stock_status_label: 'স্টকের অবস্থা',
      categories: 'ক্যাটাগরি',
    },
    purchase_checkout: {
      title: 'চেকআউট',
      purchase_details: 'ক্রয় বিস্তারিত',
    },
    supply_history: {
      title: 'ক্রয় অর্ডার হিস্টরি',
      search_label: 'ক্রয় অর্ডার হিস্টরি',
      search_placeholder: 'সার্চ অর্ডার আইডি',
      filter_btn_label: 'ফিল্টার',
      business: 'ব্যবসা',
      store: 'দোকান',
      accessing_supply_history_page: 'সরবরাহের হিস্টরি পৃষ্ঠা অ্যাক্সেস করা',
    },
    suppliers: {
      title: 'সরবরাহকারীগন',
      add: 'নতুন সরবরাহকারী রেজিস্টার করুন',
      search_label: 'সরবরাহকারী খুঁজুন',
      search_placeholder: 'কোম্পানির নাম',
    },
    preference: {
      title: 'অ্যাপের থিম পছন্দ করুন',
    },
    profile: {
      title: 'প্রোফাইলের বিস্তারিত',
    },
    user: {
      title: 'কোম্পানির ব্যবহারকারীগন',
      loading_error: 'লোড হচ্ছে না, দয়া করে পুনরায় চেষ্টা করুন',
    },
    roles: {
      title: 'রোল ব্যবস্থাপনা',
      loading_error: 'লোড হচ্ছে না, দয়া করে পুনরায় চেষ্টা করুন',
    },
    debug: {
      title: 'ডিবাগিং তথ্য',
      access_token: 'অ্যাক্সেস টোকেন',
      active_context: {
        title: 'সক্রিয় প্রসঙ্গ',
        company_id: 'কোম্পানি আইডি',
        store_id: 'স্টোর আইডি',
      },
      viewport_window: {
        title: 'ভিউপোর্ট উইন্ডো',
        width: 'প্রস্থ',
        height: 'উচ্চতা',
      },
      profile_info: {
        title: 'প্রোফাইল তথ্য',
        tableTitle: 'শিরোনাম',
        tableValue: 'মান',
        name: 'নাম',
        email: 'ইমেল',
        active: 'সক্রিয়',
        type: 'ব্যবহারকারীর ধরন',
        country: 'দেশ',
        phone: 'ফোন',
        city: 'শহর',
        gender: 'লিঙ্গ',
      },
      sentry_debug: 'Sentry Debug',
      sentry_btn_label: 'টেস্টের জন্য একটি সেন্ট্রি এরর তৈরি করুন',
    },
    companies: {
      title: 'আপনার কোম্পানিসমূহ',
      loading_error,
      details_of_company: '{{company_name}} কোম্পানির বিস্তারিত',
    },
    troubleshooting: {
      title: 'সমস্যা সমাধান',
    },
    context: {
      title: 'প্রতিষ্ঠান নির্বাচন',
      heading_title: 'আপনি কোন ব্যাবসা প্রতিষ্ঠান পরিচালনা করতে চান তা নির্বাচন করুন',
      your_companies: 'আপনার কোম্পানিসমূহ',
      stores: 'স্টোর',
      search_label: 'কোম্পানি খুঁজুন',
      search_placeholder: 'ভাই ভাই স্টোর',
    },
    login: {
      title: 'লগইন',
      welcome_title: 'Posinic-এ স্বাগতম!',
      description:
        'Posinic-এর সাথে নিরবচ্ছিন্ন লেনদেন করুন। আমাদের POS সিস্টেমের সরলতা অনুভব করতে এখনই লগ ইন করুন। Posinic বেছে নেওয়ার জন্য আপনাকে ধন্যবাদ।',
      contact_us_label: 'আপনার যদি অ্যাকাউন্ট না থাকে তাহলে আমাদের সাথে যোগাযোগ করুন',
      login_btn_label: 'ইমেল দিয়ে লগইন করুন',
      request_btn_label: 'বিনামূল্যে একটি অ্যাকাউন্টের জন্য অনুরোধ করুন',
    },
    logout: {
      title: 'লগআউট',
    },
  },
  components: {
    //Tr < LoadingScreen />
    loading_screen_label: 'অর্ডার তৈরি করা হচ্ছে',
    // Tr: <SettingsDrawer />
    settings_drawer: {
      dark_light_mode: 'ডার্ক/লাইট মোড',
      color_presets: 'অ্যাপ কালার',
      language: 'ভাষা',
    },
    // Tr: <Companies />
    companies: {
      edit: 'পরিবর্তন',
      details: 'বিস্তারিত',
      add_companies: 'নতুন কোম্পানি বানান',
    },
    // Tr: <Stores />
    stores: {
      stores: 'সব স্টোর',
      edit: 'পরিবর্তন',
      details: 'বিস্তারিত',
      add_store: 'নতুন স্টোর বানান',
    },
    // Tr: <Inventories />
    inventories: {
      inventories: 'সব ইনভেনন্টরি',
      edit: 'পরিবর্তন',
      details: 'বিস্তারিত',
    },
    // Tr: <User />
    user: {
      add_user: 'আমন্ত্রণ',
    },
    // Tr: <Roles />
    roles: {
      add_role: 'নতুন রোল বানান',
    },
    cart: {
      title: 'কার্ট',
      no_items_label: 'কার্টে কোন আইটেম নেই',
      checkout: 'চেকআউট',
      subtotal: 'সাবটোটাল',
      grand_total: 'সর্বমোট',
      total_discount: 'মোট ছাড়',
      total_vat: 'টোটাল ভ্যাট',
    },
    // Tr: <CartItem />
    cart_item: {
      remove: 'অপসারণ',
      decrease: 'কমান',
      increase: 'বৃদ্ধি',
      add_discount: 'ডিসকাউন্ট যোগ করুন',
    },
    // Tr: <DashboardNumberCard />
    inventory_overview: {
      pur_value_title: 'ইনভেন্টরি পণ্য ক্রয় মূল্য',
      min_sale_price_title: 'ইনভেন্টরির সর্বনিম্ন বিক্রয় মূল্য',
      reg_sale_price_title: 'ইনভেন্টরি মোট নিয়মিত বিক্রয় মূল্য',
    },
    // Tr: <ToggleCardTableView />
    card_table_view: {
      card: 'কার্ড',
      table: 'টেবিল',
    },

    // Tr: <DailyReport />
    daily_report: {
      facts_and_charts: 'তথ্য এবং চার্ট',
      card_view: 'কার্ড আকারে দেখুন',
      business_year: 'ব্যবসার বছর',
      select_month: 'মাস নির্বাচন করুন',
    },

    // Tr: <FileUpload />
    file_upload: {
      btn_label: 'ছবি আপলোড করুন',
    },

    // Tr: <ProductDetails />
    product_details: {
      variants_select_label: 'ভেরিয়েন্ট',
      disabled_variants_label: 'নিষ্ক্রিয়গুলো লুকিয়ে রাখুন',
      categories: 'ক্যাটাগরি',
      pur_price: 'ক্রয় মূল্য',
      min_sale: 'সর্বনিম্ন বিক্রয় মূল্য',
      reg_sale: 'নিয়মিত বিক্রয় মূল্য',
      min_sale_mobile: 'সর্বনিম্ন বিক্রয়',
      reg_sale_mobile: 'নিয়মিত বিক্রয়',
      vat: 'ভ্যাট',
      attributes: 'গুণাবলী',
    },

    // Tr: <CheckoutSidebar />
    supply_checkout_sidebar: {
      title: 'ক্রয় অর্ডার সারাংশ',
      supply_status: 'সাপ্লাই স্ট্যাটাস',
      select_payment: 'পেমেন্ট অ্যাকাউন্ট নির্বাচন করুন',
      complete: 'সম্পূর্ণ',
      subtotal: 'সাবটোটাল',
      grand_total: 'সর্বমোট',
      total_discount: 'মোট ছাড়',
      total_vat: 'টোটাল ভ্যাট',
    },
    // Tr: <VariantItem />
    variant_item: {
      pur_title: 'ক্রয় মূল্য',
      min_title: 'ন্যূনতম বিক্রয় মূল্য (মার্জিন)',
      reg_title: 'নিয়মিত বিক্রয় মূল্য (মার্জিন)',
    },
    // Tr: <OrderDetailsPanelReceiver />
    order_details: {
      change_order_status: 'অর্ডার স্ট্যাটাস পরিবর্তন করুন',
      no_transactions: 'কোন লেনদেন নেই',
      order_items: 'অর্ডার আইটেম',
      transactions: 'লেনদেন',
      inventory_history: 'ইনভেন্টরি হিস্টরি',
    },
    // Tr: <OrderQueryStateHandler />
    order_query_State_handler: {
      error_alert_title: 'অর্ডার আনার সময় ত্রুটি',
      warning_alert_title:
        'বর্তমানে আপনি শুধুমাত্র সম্পূর্ণ অর্ডার ফেরত দিতে পারেন, যেকোনো ভিন্ন ক্ষেত্রে আমাদের সাথে যোগাযোগ করুন',
      select_items: 'সব আইটেম নির্বাচন করুন',
    },
    // Tr: <ReturnOrderItemRow />
    return_order_item: {
      unit_price: 'ইউনিট মূল্য',
      net_price: 'নেট মূল্য',
      gross_price: 'মোট মূল্য',
      vat: 'ভ্যাট',
    },
    // Tr: <ReturnInformation />
    return_info: {
      title: 'রিটার্ন তথ্য',
      return_reason_label: 'রিটার্ন কারণ',
      refund_info: 'ফেরতের তথ্য',
      submit_return: 'রিটার্ন জমা দিন',
    },
    // Tr: <OrderItem />
    order_item: {
      unit_price: 'ইউনিট মূল্য',
      net_price: 'নেট মূল্য',
      gross_price: 'মোট মূল্য',
      vat: 'ভ্যাট',
      quantity: 'পরিমাণ',
    },
    // Tr: <SupplyItem />
    supply_item: {
      purchase_price: 'ক্রয় মূল্য',
      quantity: 'পরিমাণ',
    },
    // Tr: <SupplyDetailsPanelReceiver />
    supply_order_details: {
      no_transactions: 'কোন লেনদেন নেই',
      order_items: 'অর্ডার আইটেম',
      transactions: 'লেনদেন',
    },

    // Tr: <GlobalMenu />
    global_menu: {
      navigation_menu: 'ন্যাভিগেশন মেনু',
      toggle_light_Dark: 'লাইট/ডার্ক টগল করুন',
      change_theme_color: 'থিমের রঙ পরিবর্তন করুন',
    },

    // Tr: <LoadingError />
    loading_error: {
      customer_entity: 'ক্রেতা',
      transactions_entity: 'লেনদেন',
      inventory_history_entity: 'অর্ডার ইনভেন্টরি হিস্টরি',
      reload_btn_label: 'রিলোড',
      reports_entity: 'রিপোর্ট',
      products_entity: 'পণ্য',
      coa_entity: 'চার্ট অফ একাউন্ট',
      stores_entity: 'স্টোর',
    },
  },

  cards: {
    not_found: {
      title: 'কোনো {{entity}} পাওয়া যায়নি',
      type_title: 'কোনো {{entity}} {{type}}',
      transactions_entity: 'লেনদেন',
      customer_entity: 'ক্রেতা',
      companies_entity: 'কোম্পানি',
      products_entity: 'পণ্য',
      product_entity: 'পণ্য',
      selected_type: 'নির্বাচিত হয়নি',
      category_entity: 'ক্যাটাগরি',
      stores_entity: 'স্টোর',
      reports_entity: 'রিপোর্ট',
      inventory_history_entity: 'ইনভেন্টরি হিস্টরি',
      company_entity: 'কোম্পানী',
      image_entity: 'ছবি',
      variants_entity: 'ভেরিয়েন্ট',
      create_type: 'তৈরি',
      supply_history_entity: 'সাপ্লাই হিস্টরি',
      user_entity: 'ব্যবহারকারী',
      inventory_entity: 'ইনভেন্টরি',
      order_history_entity: 'অর্ডার হিস্টরি',
      coa_entity: 'চার্ট অব অ্যাকাউন্টস্',
      bank_account_entity: 'ব্যাঙ্ক অ্যাকাউন্ট',
      categories_entity: 'ক্যাটাগরি',
    },
    supplier: { edit: 'পরিবর্তন', orders: 'অর্ডার', pay_due: 'বকেয়া পরিশোধ', added_on: 'যোগ করা হয়েছে' },
    supply_product: {
      stock_count: 'মজুদের পরিমান',
      variants: 'পন্যের ধরণ',
    },
    select_categories: {
      title: 'ক্যাটাগরি নির্বাচন করুন',
    },
    category_select: { title: 'রুট', description: 'সকল বিভাগের রুট লেভেল' },
    report_feed: {
      title: 'আজকের লাইভ রিপোর্ট',
      total_sale_vat: 'মোট বিক্রয়ের পরিমাণ (ভ্যাট অন্তর্ভুক্ত):',
      total_sale: 'মোট বিক্রয় (ভ্যাট ছাড়া):',
      inventory_purchase_price: 'ইনভেন্টরি ক্রয় মূল্য:',
      net_profit: 'মোট লাভ',
      payment_methods: 'পেমেন্ট মেথড',
      cash: 'নগদ',
      other_methods: 'অন্যান্য মেথড ',
      receivable: 'প্রাপ্য',
    },
    customer: {
      change_label: 'পরিবর্তন',
      remove_label: 'অপসারণ',
      pay_due_payment: 'বকেয়া পেমেন্ট দিন',
      edit_profile: 'প্রোফাইল পরিবর্তন করুন',
      reference_details: 'রেফারেন্স বিবরণ',
      total_referrals: 'মোট রেফারেল',
      total_due_amount: 'মোট বকেয়া পরিমাণ',
      purchase_history: 'ক্রয়ের ইতিহাস',
      joined_on: 'যোগদান করেছেন',
      due_orders: 'বাকি অর্ডার',
      reference_label: 'রেফারেন্স',
    },
    customer_receivable: {
      joined_on: 'যোগদান করেছেন',
    },
    user: {
      joined_on: 'যোগদান করেছেন',
    },
    price: {
      pur_title: 'ক্রয় মূল্য',
      min_title: 'ন্যূনতম বিক্রয় মূল্য',
      reg_title: 'নিয়মিত বিক্রয় মূল্য',
      vat_inclusive: '(ভ্যাট সহ)',
    },
    order: {
      quantity: 'পরিমাণ',
      total_price: 'মোট দাম',
      seller: 'বিক্রেতা',
      user: 'ব্যবহারকারী',
      create_at: 'তৈরি হয়েছে',
      order_status: 'অর্ডার স্ট্যাটাস',
      payment_method: 'পেমেন্ট পদ্ধতি',
      payment_status: 'পেমেন্ট স্ট্যাটাস',
    },
    supply: {
      quantity: 'পরিমাণ',
      total_price: 'মোট দাম',
      seller: 'বিক্রেতা',
      user: 'ব্যবহারকারী',
      create_at: 'তৈরি হয়েছে',
      supply_status: 'সাপ্লাই স্ট্যাটাস',
      payment_status: 'পেমেন্ট স্ট্যাটাস',
      payment_info: 'পেমেন্ট তথ্য',
    },
    order_payment_info: {
      title: 'পেমেন্ট',
      select_amount_text: 'অর্থপ্রদান সম্পূর্ণ করতে অনুগ্রহ করে পরিমাণ এবং অর্থপ্রদানের পদ্ধতি নির্বাচন করুন।',
      full_btn_label: 'সম্পূর্ণ',
      wrong_amount: 'ভুল পরিমাণ',
      payment_complete: 'পেমেন্ট সম্পূর্ণ হয়েছে।',
    },
    customer_info: {
      title: 'গ্রাহকের তথ্য',
      name: 'নাম',
      email: 'ইমেল',
      phone: 'ফোন',
      address: 'ঠিকানা',
      no_customer_selected: 'কোন ক্রেতা নির্বাচিত হয়নি',
    },
    order_information: {
      title: 'অর্ডার তথ্য',
      net_price: 'নেট মূল্য',
      gross_price: 'মোট মূল্য',
      vat: 'ভ্যাট',
      due: 'বাকি',
      show_invoice: 'চালান দেখান',
    },
    order_transaction: {
      due: 'বাকি',
      refunded: 'ফেরত',
      payment: 'পেমেন্ট',
      balance: 'ব্যালেন্স',
      date: 'তারিখ',
    },
    transaction_compact: {
      expense: 'খরচ',
      liability: 'দায়',
      drawing: 'উত্তোলন',
      payment: 'পেমেন্ট',
      amount: 'এমাউন্ট',
      date: 'তারিখ',
      cash_transfer: 'ক্যাশ ট্রান্সফার',
      created_by: 'তৈরি করেছেন',
    },
    purchase_order_transaction: {
      due: 'বাকি',
      refunded: 'ফেরত',
      payment: 'পেমেন্ট',
      balance: 'ব্যালেন্স',
      date: 'তারিখ',
    },
    transactions: {
      description: 'বর্ণনা',
      expense: 'খরচ',
      order: 'অর্ডার',
      purchase: 'ক্রয়',
      liability: 'দায়',
      transfer: 'ট্রান্সফার',
      drawing: 'লভ্যাংশ',
      invest: 'বিনিয়োগ',
      due_payment: 'বাকি/দায় পরিশোধ',
      transaction_date: 'লেনদেনের তারিখ',
      created_at: 'তৈরি হয়েছে',
      account: 'অ্যাকাউন্ট',
      debit: 'ডেবিট',
      credit: 'ক্রেডিট',
      balance: 'ব্যালেন্স',
      amount: 'অ্যামাউন্ট',
      edit: 'পরিবর্তন',
    },
    chart_of_account: {
      default: 'ডিফল্ট',
      asset: 'সম্পদ',
      liability: 'দায়',
      revenue: 'রাজস্ব',
      expense: 'খরচ',
      drawing: 'উত্তোলন',
      edit_label: 'তথ্য পরিবর্তন',
      register_label: 'রেজিস্টার',
    },
    company: {},
    counter_product: {
      variants: 'টি ধরন',
    },
    inventory_compact: {
      linked: 'সংযুক্ত',
    },
    inventory_details: {
      associated_stores: 'অ্যাসোসিয়েটেড স্টোর',
    },
    store_details: {
      payment_method: 'পেমেন্ট পদ্ধতি',
    },
    variant: {
      pur_price: 'ক্রয় মূল্য',
      min_price: 'সর্বনিম্ন মূল্য',
      reg_price: 'নিয়মিত মূল্য',
      attributes: 'গুণাবলী',
      edit_info: 'তথ্য পরিবর্তন',
      duplicate: 'অনুলিপি',
      chip_label: 'নিষ্ক্রিয়',
    },
    supply_info: {
      title: 'সামারী',
      pur_price: 'ক্রয় মূল্য',
    },
    supplier_info: {
      title: 'সরবরাহকারীর তথ্য',
      name: 'নাম',
      email: 'ইমেল',
      phone: 'ফোন',
      address: 'ঠিকানা',
      no_customer_selected: 'কোন ক্রেতা নির্বাচিত হয়নি',
    },
    purchase_order_payment_info: {
      title: 'পেমেন্ট',
      select_payment_account_text: 'অর্থপ্রদান সম্পূর্ণ করতে অনুগ্রহ করে পরিমাণ এবং অর্থপ্রদানের পদ্ধতি নির্বাচন করুন।',
      pay_btn_label: 'প্রদান করা',
      full_btn_label: 'সম্পূর্ণ',
      wrong_amount: 'ভুল পরিমাণ',
      payment_complete: 'পেমেন্ট সম্পূর্ণ হয়েছে।',
    },
  },
  dialogs: {
    context_menu_title: 'যেকোনো একটি নির্বাচন করুন',
    category_title: 'ক্যাটাগরি {{title_mode}} ডায়ালগ',
    chart_of_account_title: 'চার্ট অব অ্যাকাউন্টস্ {{title_mode}} ডায়ালগ',

    customer_title: 'ক্রেতা {{title_mode}} ডায়ালগ',
    inventory_title: 'ইনভেনটরি {{title_mode}} ডায়ালগ',
    store_title: 'স্টোর {{title_mode}} ডায়ালগ',
    transaction_title: 'লেনদেন {{title_mode}} ডায়ালগ',
    user_title: 'ব্যবহারকারীর {{title_mode}} ডায়ালগ',
    add_product_inventory_title: 'ইনভেন্টরি পণ্য যোগ করুন ডায়ালগ',
    company: {
      title: 'প্রতিষ্ঠান {{title_mode}} ডায়ালগ',
      upload_logo: 'লোগো আপলোড',
    },
    variant_select: {
      title: 'অর্ডার করতে {{title}} ভেরিয়েন্ট নির্বাচন করুন',
      alert_label:
        'পণ্যটির বর্তমানে কোন সরবরাহকারী বরাদ্দ নেই। অনুগ্রহ করে বিদ্যমান তালিকা থেকে নির্বাচন করুন বা একটি নতুন সরবরাহকারী যোগ করুন',
      add_supplier: 'নতুন সরবরাহকারী যোগ করুন',
      suppliers_select_label: 'সরবরাহকারী',
      no_supplier_label: 'কোন সরবরাহকারী নেই',
      no_product_label: 'কোন পণ্য নির্বাচন করা হয়নি',
      select_supplier_title: 'সাপ্লায়ার নির্বাচন করুন',
      select_supplier_text: 'পণ্য যোগ করার জন্য আপনাকে প্রথমে সরবরাহকারী নির্বাচন করতে হবে',
      add_purchase_order: 'ক্রয় অর্ডার যোগ করুন',
    },
    prices_adjust: {
      title: 'দাম সামঞ্জস্য করুন',
      input_error: 'ইনপুট ত্রুটি',
    },
    add_discount: {
      title: 'ইউনিট প্রতি ডিসকাউন্ট যোগ করুন',
      error_input_label: 'ত্রুটি: অনুগ্রহ করে আপনার ইনপুট চেক করুন',
      wrong_input_label: 'ত্রুটি: ভুল ইনপুট, সীমা অতিক্রম করতে পারে!',
      amount: 'অ্যামাউন্ট',
      percent: 'শতাংশ',
      max_discount: 'সর্বোচ্চ ছাড়',
      discount: 'ছাড়',
      final_price: 'চূড়ান্ত মূল্য',
      add_discount: 'ছাড় যোগ করুন',
    },
    cash_payment: {
      title: 'নগদ পেমেন্ট ডায়ালগ',
      input_error_label: 'ইনপুট ত্রুটি, আপনার ইনপুট চেক করুন',
      summery: 'সারসংক্ষেপ',
      select_customer: 'একজন গ্রাহক নির্বাচন',
      complete: 'সম্পূর্ণ',
      return: 'ফেরত',
      paid: 'প্রদান',
      remaining_due: 'বকেয়া হিসাবে বাকি',
      remaining_due_customer: 'বকেয়া হিসাবে অবশিষ্ট (একজন গ্রাহক নির্বাচন)',
      send_invoice_label: 'গ্রাহকের কাছে চালান পাঠান',
      send_invoice_customer_label: 'চালান পাঠাতে একজন গ্রাহক নির্বাচন করুন',
      order_discount: 'টোটাল অর্ডার ডিসকাউন্ট',
      subtotal: 'সাবটোটাল',
      grand_total: 'সর্বমোট',
      total_discount: 'মোট ছাড়',
      total_vat: 'টোটাল ভ্যাট',
      no_email: 'কোনো ইমেল নেই',
    },
    select_products: {
      title: 'পণ্য নির্বাচন করুন ডায়ালগ',
      select_btn_label: 'পণ্য নির্বাচন করুন',
    },
    role: {
      title: 'ভূমিকা {{title_mode}} ডায়ালগ',
      import_existing_role: 'বিদ্যমান ভূমিকা থেকে আমদানি',
    },
    select_role: {
      title: 'বিদ্যমান ভূমিকা কনফিগার আমদানি করুন',
      click_import_label: 'আমদানি করতে একটি ভূমিকাতে ক্লিক/ট্যাপ করুন',
      alert_label: 'ব্যবহারকারী লোডিং ত্রুটি',
    },
    select_categories: {
      title: 'ক্যাটাগরি {{title_mode}} ডায়ালগ',
      add_btn_label: 'ক্যাটাগরি {{title}}',
      select_btn_label: 'ক্যাটাগরি নির্বাচন করুন',
      alert_label: 'কোন বিভাগ পাওয়া যায় নি',
    },
    select_customer: {
      title: 'ক্রেতা নির্বাচন করুন',
      search_label: 'ক্রেতা খুঁজুন',
      search_placeholder: 'নাম ইমেল বা ফোন নম্বর দিয়ে খুঁজুন',
      add_customer: 'ক্রেতা {{text}}',
    },
    product: {
      title: 'একটি পণ্য {{title_mode}} ডায়ালগ',
      categories_step_label: 'ক্যাটাগরি নির্বাচন',
      variants_step_label: 'ভ্যারিয়েন্ট তথ্য',
      product_step_label: 'পণ্য তথ্য',
      summery_step_label: 'সারসংক্ষেপ',
      save_btn_label: '{{title_mode}} পণ্য সংরক্ষণ করুন',
    },
    variant: {
      title: 'পণ্য ভ্যারিয়েন্ট {{title_mode}} ডায়ালগ',
      add_btn_label: 'ভ্যারিয়েন্ট {{title_mode}}',
      add_barcode: 'বারকোড যোগ করুন',
      scan_btn_label: 'স্ক্যান',
    },
    supplier: {
      title: 'একটি সরবরাহকারী {{title_mode}} ডায়ালগ',
      supplier_products_label: 'সরবরাহকারী পণ্য',
      company_step_label: 'তথ্য',
      product_step_label: 'পণ্য',
      summery_step_label: 'সারসংক্ষেপ',
      add_product_btn_label: 'পণ্য {{title_mode}}',
      save_btn_label: 'সরবরাহকারী সংরক্ষণ করুন',
      contact_information: 'যোগাযোগের তথ্য',
      products: 'পণ্য',
    },
    register_expense: {
      title: 'খরচ রেজিস্টার করুন',
      save_btn_label: 'লেনদেন সংরক্ষণ করুন',
      transaction_info: 'লেনদেনের তথ্য',
      expense: 'খরচ',
      liability: 'দায়',
      amount: 'এমাউন্ট',
      input_error_label: 'ইনপুট ত্রুটি, আপনার ইনপুট চেক করুন',
      'account-helper-text': 'এই অ্যাকাউন্টের জন্য আপনি অর্থপ্রদান করছেন',
      'step-1-instruction': 'আপনি কি একটি খরচ যোগ করতে চান নির্বাচন করুন',
      'step-2-instruction': 'নগদ অ্যাকাউন্ট নির্বাচন করুন',
    },
    register_drawing: {
      title: 'উত্তোলন রেজিস্টার করুন',
      save_btn_label: 'লেনদেন সংরক্ষণ করুন',
      transaction_info: 'লেনদেনের তথ্য',
      input_error_label: 'ইনপুট ত্রুটি, আপনার ইনপুট চেক করুন',
      drawing: 'উত্তোলন',
      amount: 'এমাউন্ট',
      'account-helper-text': 'এই অ্যাকাউন্টের জন্য আপনি অর্থপ্রদান করছেন',
      'step-1-instruction': 'আপনার উইথড্র অ্যাকাউন্ট নির্বাচন করুন',
      'step-2-instruction': 'নগদ অ্যাকাউন্ট নির্বাচন করুন',
    },
    cash_transfer: {
      title: 'রেজিস্টার ক্যাশ ট্রান্সফার',
      amount: 'এমাউন্ট',
      input_error_label: 'ইনপুট ত্রুটি, আপনার ইনপুট চেক করুন',
      amount_error_label: '{{coa_name}} চেয়ে ইনপুট পরিমাণ বেশি',
      balance_zero_error_label: '{{coa_name}} অ্যাকাউন্ট ব্যালেন্স শূন্য',
      save_btn_label: 'লেনদেন সংরক্ষণ করুন',
      no_balance: 'ব্যালেন্স নেই',
    },
    update_number: {
      input_error_label: 'ইনপুট ত্রুটি, আপনার ইনপুট চেক করুন',
      quantity_error_label: 'স্টক পরিমাণের চেয়ে ইনপুট সংখ্যা বেশি',
    },
    invoice_details: {
      title: 'চালানের বিস্তারিত',
      purchase_date: 'ক্রয় তারিখ:',
      order: 'অর্ডার',
      payment_status: 'পেমেন্ট স্ট্যাটাস',
      order_status: 'অর্ডার স্ট্যাটাস',
      unit_price: 'ইউনিট মূল্য',
      table_cell_title: 'শিরোনাম',
      table_cell_unit_price: 'ইউনিট মূল্য',
      table_cell_vat: 'ভ্যাট',
      table_cell_discount: 'ডিসকাউন্ট',
      table_cell_quantity: 'পরিমাণ',
      table_cell_price: 'দাম',
      footer_title: 'নোটস',
      footer_description: 'এই চালান দিয়ে তৈরি করা হয় ',
      footer_description_link: 'Posinic POS সিস্টেম',
      have_question: 'একটি প্রশ্ন আছে?',
      total: 'মোট',
      paid: 'প্রদান',
      due: 'বাকি',
      share_details_title: 'শেয়ার করুন',
      subtotal: 'সাবটোটাল',
      grand_total: 'সর্বমোট',
      total_discount: 'মোট ছাড়',
      total_vat: 'টোটাল ভ্যাট',
    },
    invoice_share_details: {
      title: 'শেয়ার করুন',
      send_email: 'ইমেল পাঠান',
      send_invoice_title: 'অর্ডার চালান পাঠান',
      email_label: 'ইমেল ঠিকানা',
      whatsapp: 'হোয়াটসঅ্যাপ',
      email: 'ইমেল',
    },
  },
  forms: {
    // forms react components and validation schemas both would be here
    email_submit: {
      email_label: 'আপনার ইমেইল দিয়ে লগইন করুন',
      no_account_found: 'কোন অ্যাকাউন্ট পাওয়া যায়নি!',
    },
    otp_submit: {
      request_new_code: 'নতুন কোড অনুরোধ করুন',
      try_another_email: 'আরেকটি ইমেইল চেষ্টা',
    },
    transaction: {
      debit_account_label: 'ডেবিট অ্যাকাউন্ট',
      credit_account_label: 'ক্রেডিট অ্যাকাউন্ট',
      description_label: 'বিবরণ',
      description_placeholder: 'এই অ্যাকাউন্টটি SCB কুয়ালালামপুরের অধীনে। মিস্টার জন ডো অ্যাকাউন্ট ম্যানেজার।',
      amount_label: 'ব্যালেন্স',
      amount_placeholder: '১০০,০০',
      transaction_date_label: 'লেনদেনের তারিখ',
    },
    add_to_inventory: {},
    address_fields: {
      road_label: 'রাস্তা',
      road_placeholder: 'রেগিনহার্ডস্ট্রাসে',
      house_number_label: 'বাড়ির নম্বর',
      house_number_placeholder: '৬৫',
      zip_code_label: 'জিপ কোড',
      zip_code_placeholder: '১৩৪০৯',
      city_label: 'শহর',
      city_placeholder: 'বার্লিন',
      country_label: 'দেশ',
    },
    bar_code: {
      label: 'বারকোড',
    },
    category: {
      title_label: 'ক্যাটাগরি নাম',
      title_placeholder: 'টি-শার্ট',
      description_label: 'বিবরণ',
      description_placeholder: 'ক্যাটাগরি বিস্তারিত',
    },
    chart_of_account: {
      account_name_label: 'হিসাবের নাম',
      account_name_placeholder: 'SCB কুয়ালামপুর #48270',
      account_type_label: 'অ্যাকাউন্ট ধরন',
      account_type_placeholder: 'সম্পদ',
      description_label: 'বিবরণ',
      description_placeholder: 'এই অ্যাকাউন্টটি SCB কুয়ালামপুরের অধীনে। মিস্টার জন ডো অ্যাকাউন্ট ম্যানেজার।',
      active_label: 'আপনি কি অ্যাকাউন্ট সক্রিয় করতে চান?',
      payment_possible_label: 'আপনি কি অর্থপ্রদানের জন্য এই অ্যাকাউন্টটি ব্যবহার করতে চান?',
      cash_account_label: 'আপনি কি এই অ্যাকাউন্টটি নগদ অ্যাকাউন্টের জন্য ব্যবহার করতে চান?',
    },
    expanse_transaction: {
      select_account_label: 'আপনি যে অ্যাকাউন্ট থেকে অর্থপ্রদান করতে চান',
      description_label: 'বিবরণ',
      description_placeholder: 'এই অ্যাকাউন্টটি SCB কুয়ালামপুরের অধীনে। মিস্টার জন ডো অ্যাকাউন্ট ম্যানেজার।',
      balance_zero_error_label: '{{coa_name}} অ্যাকাউন্ট ব্যালেন্স শূন্য',
      no_balance: 'ব্যালেন্স নেই',
    },
    drawing_transaction: {
      select_account_label: 'আপনি যে অ্যাকাউন্ট থেকে অর্থপ্রদান করতে চান',
      description_label: 'বিবরণ',
      description_placeholder: 'এই অ্যাকাউন্টটি SCB কুয়ালামপুরের অধীনে। মিস্টার জন ডো অ্যাকাউন্ট ম্যানেজার।',
      balance_zero_error_label: '{{coa_name}} অ্যাকাউন্ট ব্যালেন্স শূন্য',
      no_balance: 'ব্যালেন্স নেই',
    },
    company: {
      title_label: 'প্রতিষ্ঠানের নাম',
      title_placeholder: 'পানশীলাব',
      currency_label: 'মুদ্রা',
    },
    customer: {
      first_name_label: 'প্রথম নাম',
      first_name_placeholder: 'জন',
      last_name_label: 'শেষ নাম',
      last_name_placeholder: 'ডিওই',
      email_label: 'ইমেল',
      email_placeholder: 'example@domain.com',
      phone_number_label: 'ফোন নম্বর',
      phone_number_placeholder: '০১৭৪০৮৩৭৪৫০',
      reference_label: 'গ্রাহক রেফারেন্স',
      reference_id: 'রেফারেন্স আইডি',
      is_referable_label: 'রেফারেন্সের জন্য ব্যবহার করবেন?',
      is_referable_helperText:
        'আপনি রেফারেন্স ক্ষমতা সরিয়ে ফেলতে পারবেন যদি গ্রাহক রেফারেন্স হিসেবে ব্যবহার না হয়ে থাকে।',
      reference_dropdown_no_options_text: 'কোন উল্লেখযোগ্য ক্রেতা পাওয়া যায়নি',
      description_label: 'বিবরণ',
      description_placeholder: 'গ্রাহকের বিবরণ এখানে',
    },
    fieldset: {
      user_info_title: 'ব্যক্তিগত তথ্য',
      assignments_and_permissions_title: 'অ্যাসাইনমেন্ট এবং অনুমতি',
      address_info_title: 'ঠিকানা',
      chart_of_account_title: ' চার্ট অব অ্যাকাউন্টস্ বিস্তারিত',
      company_title: 'প্রতিষ্ঠানের তথ্য',
      customer_title: 'ব্যক্তিগত তথ্য',
      permissions_title: 'অনুমতি',
      role_title: 'ভুমিকা তথ্য',
      core_info_title: 'মূল তথ্য',
      supplier_title: 'কোম্পানির তথ্য',
      attributes_info_title: 'গুণাবলী',
      images_info_title: 'ছবি',
      barcode_info_title: 'বারকোড',
    },
    inventory: {
      title_label: 'ইনভেনটরি নাম',
      title_placeholder: 'বার্লিন ইনভেনটরি',
    },
    owner_details: {
      first_name_label: 'প্রথম নাম',
      first_name_placeholder: 'জন',
      last_name_label: 'শেষ নাম',
      last_name_placeholder: 'ডিওই',
      phone_number_label: 'ফোন নম্বর',
      phone_number_placeholder: '০১৭৪০৮৩৭৪৫০',
      city_label: 'শহর',
      city_placeholder: 'বার্লিন',
      country_label: 'দেশ',
    },
    payment_method: {
      label: 'মেথড',
      add_btn_label: 'মেথড যোগ করুন',
    },
    product: {
      title_label: 'শিরোনাম',
      title_placeholder: 'পণ্যের নাম',
      unit_label: 'ইউনিট',
      vat_label: 'ভ্যাট',
      vat_placeholder: '১০',
      product_type_label: 'পণ্যের ধরন নির্বাচন করুন',
      description_label: 'বিবরণ',
      description_placeholder: 'পণ্যের বিবরণ এখানে',
    },
    roles: {
      title_label: 'শিরোনাম',
      title_placeholder: 'ম্যানেজার',
      description_label: 'বিবরণ',
      description_placeholder: 'রোলের বিবরণ এখানে',
    },
    store: {
      title_label: 'স্টোরের নাম',
      title_placeholder: 'বার্লিন স্টোর',
      fieldset_title: 'স্টোরের তথ্য',
    },
    supplier: {
      title_label: 'কোম্পানির নাম',
      title_placeholder: 'পানশীলাব',
      email_label: 'ইমেল',
      email_placeholder: 'example@domain.com',
      phone_number_label: 'ফোন নম্বর',
      phone_number_placeholder: '০১৭৪০৮৩৭৪৫০',
    },
    user: {
      first_name_label: 'প্রথম নাম',
      first_name_placeholder: 'জন',
      last_name_label: 'শেষ নাম',
      last_name_placeholder: 'ডিওই',
      email_label: 'ইমেল',
      email_placeholder: 'example@domain.com',
      phone_number_label: 'ফোন নম্বর',
      phone_number_placeholder: '০১৭৪০৮৩৭৪৫০',
      role_label: 'ভূমিকা',
      assigned_stores_label: 'নির্ধারিত দোকান',
      assigned_inventories_label: 'বরাদ্দকৃত ইনভেন্টরি',
      staff_btn_label: 'স্টাফ',
      partner_btn_label: 'পার্টনার',
    },
    variant: {
      title_label: 'শিরোনাম',
      title_placeholder: 'যেমন: iPhone 15 128 GB',
      stock_threshold_label: 'লো স্টক থ্রেশহোল্ড',
      stock_threshold_placeholder: '১০',
      ser_sale_price_label: 'প্রস্তুতি খরচ',
      pur_sale_price_label: 'ক্রয় মূল্য',
      pur_sale_price_placeholder: 'ক্রয় মূল্য',
      min_sale_price_label: 'ন্যূনতম বিক্রয় মূল্য',
      min_sale_price_placeholder: 'ন্যূনতম বিক্রয় মূল্য',
      reg_sale_price_label: 'নিয়মিত বিক্রয় মূল্য',
      reg_sale_price_placeholder: 'নিয়মিত বিক্রয় মূল্য',
      background_mode_label: 'ব্যাকগ্রাউন্ড মোড',
    },
    attribute: {
      title_label: 'শিরোনাম',
      title_placeholder: 'শিরোনাম',
      title_helper_text: 'অ্যাট্রিবিউট শিরোনাম প্রয়োজন',
      title_exist_helper_text: 'আগে থেকেই আছে',
      value_label: 'মান',
      value_placeholder: 'মান',
      value_helper_text: 'অ্যাট্রিবিউট মান প্রয়োজন',
    },
  },
  filter: {
    sale_reports: {
      ...common_filter,
      text: 'নির্দিষ্ট বিক্রয় প্রতিবেদন দেখতে ফিল্টার নির্বাচন করুন। ডিফল্ট ফিল্টার সেট করতে রিসেট বোতামে ক্লিক করুন।',
      business_year: 'ব্যবসার বছর',
    },
    order_history: {
      ...common_filter,
      text: 'নির্দিষ্ট অর্ডার হিস্টরি দেখতে ফিল্টার নির্বাচন করুন। সমস্ত ফিল্টার সাফ করতে পরিষ্কার বোতামে ক্লিক করুন।',
      order_status: 'অর্ডার অবস্থা',
      payment_status: 'পেমেন্ট অবস্থা',
      seller: 'বিক্রেতা',
      customer: 'ক্রেতা',
    },
    products: {
      ...common_filter,
      text: 'নির্দিষ্ট ইনভেন্টরি পণ্য দেখতে ফিল্টার নির্বাচন করুন। সমস্ত ফিল্টার সাফ করতে পরিষ্কার বোতামে ক্লিক করুন।',
      inventory: 'ইনভেন্টরি',
      stock_status: 'স্টক অবস্থা',
    },
    inventory_history: {
      ...common_filter,
      text: 'নির্দিষ্ট ইনভেন্টরি হিস্টরি দেখতে ফিল্টার নির্বাচন করুন। সমস্ত ফিল্টার সাফ করতে পরিষ্কার বোতামে ক্লিক করুন।',
      reason: 'কারণ',
      inventory: 'ইনভেন্টরি',
    },
    supply_history: {
      ...common_filter,
      text: 'নির্দিষ্ট ক্রয় অর্ডার হিস্টরি দেখতে ফিল্টার নির্বাচন করুন। সমস্ত ফিল্টার সাফ করতে পরিষ্কার বোতামে ক্লিক করুন।',
      supply_status: 'সরবরাহের অবস্থা',
      payment_status: 'লেনদেনের অবস্থা',
      receiver: 'রিসিভার',
      supplier: 'সরবরাহকারী',
    },
  },
  snackbar: {
    token_copy: 'পাঠ্যটি আপনার ক্লিপবোর্ডে অনুলিপি করা হয়েছে।',
    invoice_link_copy: 'চালান লিঙ্ক কপি সফল হয়েছে।',
    discount_applied_successfully: 'ছাড় সফলভাবে প্রয়োগ করা হয়েছে!',
  },
  navigation: {
    dashboard: 'ড্যাশবোর্ড',
    chart_of_accounts: 'চার্ট অব অ্যাকাউন্টস্',
    register: 'সাধারন রেজিস্টার',
    expense: 'পেমেন্ট / খরচ',
    drawing: 'উত্তোলন',
    cash_transfer: 'ক্যাশ ট্রান্সফার',
    transactions: 'ট্রানজেকশনসমূহ',
    sale: 'বিক্রয়',
    daily: 'দৈনিক',
    monthly: 'মাসিক',
    yearly: 'বাৎসরিক',
    profit_and_loss: 'লাভ এবং ক্ষতি',
    purchase: 'ক্রয়',
    purchase_order: 'ক্রয় অর্ডার',
    customer_due: 'গ্রাহক বকেয়া',
    payable: 'ক্রয়বাবদ বাকি',
    return: 'ফেরত',
    history: 'অর্ডার হিস্টরি',
    invoice: 'চালান',
    customers: 'ক্রেতাসমূহ',
    products: 'পন্যসমূহ',
    category_management: 'ক্যাটাগরি পরিচালনা',
    overview: 'পরিদর্শন',
    checkout: 'চেকআউট',
    suppliers: 'সরবারাহকারী',
    company: 'প্রতিষ্ঠান',
    user: 'ব্যাবহারকারী',
    profile: 'প্রোফাইল',
    roles: 'রোলসমূহ',
    preference: 'থিম/ভাষা...',
    debug: 'ডিবাগ',
    context: 'প্রতিষ্ঠান নির্বাচন',
    troubleshooting: 'সমস্যা সমাধান',
    logout: 'লগআউট',
    settings: 'সেটিংস',
    accounting: 'হিসাবরক্ষন',
    reports: 'রিপোর্টসমূহ',
    counter: 'কাউন্টার',
    order: 'অর্ডার',
    inventory: 'ইনভেনটরি',
    supply: 'সরবারাহ',
    supplier: 'সরবারাহকারী',
    operations: 'কার্যক্রম',
    companies: 'প্রতিষ্ঠানসমূহ',
    customer: 'ক্রেতা',
    loading_error: 'লোড করতে সমস্যা হয়েছে',
    reload_app: 'পুনরায় লোড অ্যাপ',
    reset_app: 'রিসেট অ্যাপ',
    menu: 'মেনু',
  },
  paymentMethods: {
    card: 'কার্ড',
    cash: 'ক্যাশ',
    receivable: 'ক্রেতার বাকি',
  },
  user: `ব্যাবহারকারী`,
  companies: `প্রতিষ্ঠান`,
  profile: `profile`,
  preference: 'theme preference',
  debug: `debug`,
  context: `context`,
  lock: `Lock App`,
  settings: `settings`,
  roles: `roles`,
  error: 'সমস্যা!',
  checkout: {
    add_order_discount: 'অর্ডার ডিসকাউন্ট যোগ করুন',
    complete: 'সম্পূর্ণ',
    grand_total: 'সর্বমোট',
    order_discount: 'অর্ডার ডিসকাউন্ট',
    pay: 'প্রদান করা',
    send_kitchen: 'রান্নাঘরে পাঠাও',
    select_customer: 'ক্রেতা নির্বাচন করুন',
    reset_order_discount: 'ডিসকাউন্ট মুছুন',
    subtotal: 'সাবটোটাল',
    title: 'অর্ডার সারসংক্ষেপ',
    total_discount: 'মোট ছাড় (ভ্যাট সহ)',
    total_vat: 'টোটাল ভ্যাট',
    update_order_discount: 'আপডেট অর্ডার ডিসকাউন্ট',
    payment_methods: {
      receivable_warning:
        'বাকিতে বিক্রি করতে একজন ক্রেতা নির্বাচন করা বাধ্যতামুলক, ক্রেতা নির্বাচনট করতে "ক্রেতা নির্বাচন করুন" বাটনে চাপ দিন',
      card: 'কার্ড',
      cash: 'নগদ',
      receivable: 'বাকি',
    },
  },
  tooltip: {
    dialog_disabled_step_next_btn_title: 'অনুগ্রহ করে প্রথম ধাপগুলো সম্পূর্ণ করুন।',
    download_title: 'ডাউনলোড করুন',
    share_title: 'শেয়ার করুন',
    show_hide_category_title: 'এই ক্যাটাগরির সন্তান দেখান/লুকান',
    edit_category_title: 'এই ক্যাটাগরির পরিবর্তন',
    move_category_title: 'অন্য অভিভাবক ক্যাটাগরির অধীনে সরান',
    copy_category_title: 'এই ক্যাটাগরি কপি করুন',
    add_child_category_title: 'এই ক্যাটাগরির সন্তান যোগ করুন',
  },
  alert: {
    handle_no_stock: {
      title: 'স্টক নেই!',
      description: 'স্টক পরিমাণের চেয়ে কার্ট আইটেমের পরিমাণ বেশি',
    },
    dialog_incomplete_step: {
      title: 'অসম্পূর্ণ ফর্ম!',
      description: 'এই পদক্ষেপটি অ্যাক্সেসযোগ্য নয়',
    },
    not_editable_role: {
      success_btn_text: 'আমি বুঝেছি',
      title: 'পরিবর্তনযোগ্য নয়',
      description:
        'ডিফল্ট ভূমিকা পরিবর্তনযোগ্য নয়, আপনি নিজের মতো করে কাস্টমাইজ করার জন্য নতুন ভূমিকা তৈরি করতে পারেন',
    },
    multiple_companies: {
      title: 'সতর্কতা!',
      description: 'একাধিক কোম্পানি! যে কম্পানিটি ব্যবহার করতে তা নির্বাচন করুন',
      select_btn_label: 'প্রসঙ্গ নির্বাচন করুন',
    },
    successfully_logout: {
      title: 'আপনি সফলভাবে লগ আউট করেছেন।',
      description: 'দুর্দান্ত ব্যবসায়িক জিনিসগুলিতে কাজ চালিয়ে যেতে আবার লগ ইন করুন!',
      login_btn_label: 'লগইন এ যান',
    },
    otp_failed: {
      title: 'OTP ব্যর্থ!',
      description: 'অনুগ্রহ করে আপনার OTP কোড পুনরায় পরীক্ষা করুন।',
    },
    otp_success: {
      title: 'লগইন কোডের জন্য ইমেল চেক করুন',
      description:
        'আপনি একটি ইমেল না পেলে, আপনার স্প্যাম ফোল্ডার চেক করুন. এছাড়াও আপনি একটি নতুন কোড অনুরোধ করতে পারেন',
    },
    handle_set_context: {
      title: '{{description}} করার আগে আপনাকে একটি {{title}} নির্বাচন করতে হবে।',
      description: `আপনি একটি {{title}} নির্বাচন করতে চান?`,
      select_btn_label: '{{title}} নির্বাচন করুন',
    },
    partially_returned: {
      title: 'সতর্কতা!',
      description: 'আংশিকভাবে ফেরত দেওয়া অর্ডার আবার ফেরত দেওয়া যাবে না',
      confirm_btn_text: 'আমি বুঝি',
    },
    already_returned: {
      title: 'সতর্কতা!',
      description: 'ইতিমধ্যে ফেরত দেওয়া অর্ডার আবার ফেরত দেওয়া যাবে না',
      confirm_btn_text: 'আমি বুঝি',
    },
    partially_paid: {
      title: 'সতর্কতা!',
      description:
        'আংশিক অর্থ প্রদানের অর্ডার এই মুহুর্তে ফেরত দেওয়া যাবে না। সাহায্য পেতে সহায়তার সাথে যোগাযোগ করুন',
      confirm_btn_text: 'আমি বুঝি',
    },
  },
  confirm: {
    troubleshooting: {
      title: 'আপনি কি নিশ্চিত?',
      description: 'আপনি আপনার সমস্ত সংরক্ষণ না করা ডেটা হারাবেন!',
    },
    debug: {
      title: 'নিশ্চিত?',
      description: 'আপনি কি নিশ্চিত যে আপনি সেন্ট্রি চেকের জন্য একটি ত্রুটি ছুঁড়ে ফেলতে চান?',
    },
    add_category_management: {
      title: 'পণ্যগুলিকে নতুন বিভাগে নিয়ে যাবেন?',
      description:
        'বর্তমান অভিভাবক বিভাগের পণ্য বরাদ্দ করা হয়েছে. এই ক্রিয়াটি অভিভাবক বিভাগের বিদ্যমান পণ্যগুলিকে নতুন তৈরি করা বিভাগের অধীনে স্থানান্তরিত করবে।',
    },
    variants: {
      title: 'আপনি কপি করতে নিশ্চিত?',
      description: 'আপনি কি নিশ্চিত আপনি বর্তমান ভ্যারিয়েন্ট কপি করতে চান?',
    },
    order_success: {
      title: 'সফল!',
      description: 'ক্রয় অর্ডার তৈরি সফল হয়েছে৷',
      confirm_btn_text: 'কাউন্টারে',
      deny_btn_text: 'চালান দেখান',
    },
    order_error: {
      title: 'কিছু ভুল',
      description: 'অর্ডার তৈরি করা ব্যর্থ হয়েছে!',
      confirm_btn_text: 'ঠিক আছে, আবার চেষ্টা করুন',
    },
    order_timeout: {
      title: 'সময় শেষ!',
      description:
        'অর্ডার তৈরির সময় শেষ হয়েছে, অর্ডারটি সফলভাবে তৈরি হতে পারে কিন্তু নেটওয়ার্ক বাধার কারণে এটি নিশ্চিত করা যায়নি, একটি নতুন অর্ডার তৈরি করার আগে অনুগ্রহ করে অর্ডার ইতিহাস চেক করুন।',
      confirm_btn_text: 'ইতিহাস অর্ডার করতে',
    },
    send_to_kitchen: {
      success_title: 'সফল!',
      error_title: 'কিছু ভুল',
      success_description: 'রান্নাঘরে পাঠানো হয়েছে',
      error_description: 'রান্নাঘরে পাঠাতে ব্যর্থ',
      success_btn_text: 'কাউন্টারে',
      error_btn_text: 'ঠিক আছে, আবার চেষ্টা করুন',
    },
    submit_due_payment: {
      title: 'বকেয়া পেমেন্ট করবেন?',
      description: 'আপনি কি ব্যবহারকারী {{name}} জন্য বকেয়া অর্থ যোগ করতে চান?',
    },
    submit_return: {
      title: 'আপনি কি নিশ্চিত?',
      description: 'আপনি কি সেই আইটেমগুলি ফেরত দেওয়ার বিষয়ে নিশ্চিত?',
    },
    mark_as_completed: {
      title: 'সমাপ্ত হিসাবে চিহ্নিত করবেন?',
      description: 'আপনি কি এই অর্ডারটিকে সম্পূর্ণ হিসাবে চিহ্নিত করার বিষয়ে নিশ্চিত?',
    },
    mark_as_pending: {
      title: 'পেন্ডিং হিসাবে চিহ্নিত করবেন?',
      description: 'আপনি কি এই অর্ডারটিকে পেন্ডিং হিসাবে চিহ্নিত করার বিষয়ে নিশ্চিত?',
    },
    mark_as_refunded: {
      title: 'ফেরত হিসেবে চিহ্নিত করবেন?',
      description: 'আপনি কি নিশ্চিত যে আপনি এই অর্ডারটিকে ফেরত হিসেবে চিহ্নিত করতে চান?',
    },
    mark_as_cancelled: {
      title: 'বাতিল হিসেবে চিহ্নিত করবেন?',
      description: 'আপনি কি নিশ্চিত যে আপনি এই অর্ডারটিকে বাতিল হিসেবে চিহ্নিত করতে চান?',
    },
    mark_as_pending_delivery: {
      title: 'পেন্ডিং ডেলিভারি হিসেবে চিহ্নিত করবেন?',
      description: 'আপনি কি নিশ্চিত যে আপনি এই অর্ডারটিকে পেন্ডিং ডেলিভারি হিসেবে চিহ্নিত করতে চান?',
    },
    mark_as_delivery_in_progress: {
      title: 'ডেলিভারি চলছে বলে চিহ্নিত করবেন?',
      description: 'আপনি কি নিশ্চিত যে আপনি এই অর্ডারটিকে ডেলিভারি চলছে বলে চিহ্নিত করতে চান?',
    },
    mark_as_delivered: {
      title: 'ডেলিভারি করা হিসাবে চিহ্নিত করবেন?',
      description: 'আপনি কি নিশ্চিত যে আপনি এই অর্ডারটিকে ডেলিভারি করা হিসাবে চিহ্নিত করতে চান?',
    },

    process_payment_handler: { title: 'পেমেন্ট করুন?', description: 'আপনি কি নিশ্চিত যে আপনি অর্থপ্রদান করতে চান?' },
    delete_order_item: { title: 'আইটেম মুছবেন?', description: 'আপনি অর্ডার আইটেম মুছে ফেলার বিষয়ে নিশ্চিত?' },

    logout_alert: {
      title: 'লগআউট নিশ্চিতকরণ?',
      description: 'আপনি কি নিশ্চিত যে আপনি লগ আউট করতে চান?',
    },
  },
  swal: {
    agree: 'হ্যা, বুঝতে পেরেছি',
    cancel: 'বাতিল করুন',
  },
};
